import React, {useState} from "react";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppBar from './appbar';
import MarkupAnno from './MarkupAnno';
import Markup from "./Markup";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import {createTheme} from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import {getNouns, getDeclension} from './words'
import {GetLesson, GetLessonNames, GetLessonNameandTitles} from './lessons'
import UnitSelector from './UnitSelector';
import VerbTraining from './VerbTraining';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import {Card, CardActionArea, CardContent, CardHeader} from "@mui/material";

function markupFromPassage(passage) {
    let words = [];
    let syntax = [];
    let groupSyntax;
    let groupStart, groupEnd;
    for (const elem of passage) {
        if(elem.granularity == 'word') {
            words.push({role: elem.role, number: elem.number, text: elem.text});
        }
        else if(elem.granularity == 'group') {
            groupStart = words.length;
            for (const word of elem.children) {
                words.push({granularity: 'word', role: word.role, number: word.number, text: word.text});
            }
            groupEnd = words.length-1;
            syntax.push({granularity: 'group', groupStart: groupStart, groupEnd: groupEnd, role: elem.role});
        }
        else if(elem.granularity == 'syntax') {
            syntax.push({granularity: 'syntax', groupStart: words.length, groupEnd: words.length-1, role: elem.role});
        }
    }
    return {words: words, syntax: syntax}
}



export default function App() {
    const [passageIndex, setPassageIndex] = useState(0);
    const [lessonName, setLessonName] = useState(GetLessonNames()[0]);
    const [passages, setPassages] = useState(GetLesson(lessonName));
    const [darkMode, setDarkMode] = useState(true);
    const [screen, setScreen] = useState('verbs');
    //const [screen, setScreen] = useState('markup');
    //const [screen, setScreen] = useState('lessons');
    //const [screen, setScreen] = useState('home');
    const [maintenance, setMaintenance] = useState(true);


    let lessonNames = GetLessonNames();

    const GoHome = (screen) => {
        setScreen(screen);
    }

    const cardCB = (event) => {
        //console.log(event.currentTarget.getAttribute('value'));
        setScreen(event.currentTarget.getAttribute('value'));
    }


    const switchDarkCB = () => {
        setDarkMode(!darkMode);
    }

    const switchLessonCB = (lessonName) => {
        let lesson = GetLesson(lessonName);
        setLessonName(lessonName)
        setPassages(lesson);
        setPassageIndex(0);
        setScreen('markup');
    }

    const changePassage = (indexChange) => {
        if(indexChange == -1 && passageIndex > 0) {
            setPassageIndex(passageIndex - 1);
        }
        if(indexChange == 1 && passageIndex < passages.length-1) {
            setPassageIndex(passageIndex + 1);
        }
    }

    const updateWord = (wordIndex, role, number) => {
        let tmpPassages = [...passages];
        let tmpPassage = tmpPassages[passageIndex];
        let tmpWords = [...tmpPassage.markupAttempt.words];
        let tmpWord = tmpWords[wordIndex];
        tmpWord.number = number;
        tmpWord.role = role;
        tmpPassage.markupAttempt.words[wordIndex] = tmpWord;
        tmpPassages[passageIndex] = tmpPassage;
        setPassages(tmpPassages);
    }

    const updateSyntax = (syntax) => {
        let tmpPassages = [...passages];
        let tmpPassage = tmpPassages[passageIndex];
        let tmpSyntax = [...tmpPassage.markupAttempt.syntax];
        let tmpSyntaxElement = tmpSyntax.find(item => item.groupStart === syntax.start && item.groupEnd === syntax.end);
        if(tmpSyntaxElement == undefined) {
            let followingStart = undefined;
            // check for overlaps
            for(let e=0; e<tmpSyntax.length; e++) {
                if(tmpSyntax[e].groupStart == syntax.end || tmpSyntax[e].groupEnd == syntax.start) {
                    console.log('overlap')
                    return;
                }
                if (tmpSyntax[e].groupStart < syntax.start && tmpSyntax[e].groupEnd > syntax.start &&
                    tmpSyntax[e].groupEnd < syntax.end) {
                    console.log('overlap')
                    return;
                }
                if (tmpSyntax[e].groupStart > syntax.start && tmpSyntax[e].groupStart < syntax.end &&
                    tmpSyntax[e].groupEnd > syntax.end) {
                    console.log('overlap')
                    return;
                }
            }
            tmpSyntaxElement = {groupStart: syntax.start, groupEnd: syntax.end, granularity: 'group', role: syntax.role};
            tmpSyntax.push(tmpSyntaxElement);
            tmpSyntax.sort((a, b) => {return (a.groupStart != b.groupStart) ? a.groupStart - b.groupStart :
                b.groupEnd - a.groupEnd});
        }
        else {
            console.log('found ' + tmpSyntaxElement)
        }
        tmpPassage.markupAttempt.syntax = tmpSyntax;
        tmpPassages[passageIndex] = tmpPassage;
        setPassages(tmpPassages);
    }

    const clearMarkup = () => {
        let tmpPassages = [...passages];
        let tmpPassage = {text: passages[passageIndex].text, markupAttempt: {words: [], syntax: []}, markupAnswer: passages[passageIndex].markupAnswer};
        if(passages[passageIndex].markupAnswer.words.length > 0) {
            tmpPassage.markupAttempt.words = passages[passageIndex].markupAnswer.words.map((item, index) => {
                return {role: 'none', number: 'none', text: item.text}});
        }
        else {
            tmpPassage.markupAttempt.words = tmpPassage.text.split(" ").map((item, index) => {
                return {role: 'none', number: 'none', text: item}
            });
        }
        tmpPassages[passageIndex] = tmpPassage;
        setPassages(tmpPassages);
    }

    let passage = passages[passageIndex];
    //console.log(passage)

    let theme = createTheme({
        palette: {
            mode: darkMode ? 'dark': 'light',
        },
    });

    let display;
    if(screen == 'lessons') {
        display = <UnitSelector lessonCB={switchLessonCB} lessons={GetLessonNameandTitles().map((lesson) => ({name: lesson.name, title: lesson.title}))} />
    }
    else if (screen == 'markup') {
        display = <MarkupAnno passage={passage} changePassage={changePassage} updateWordCB={updateWord} updateSyntaxCB={updateSyntax} deleteMarkUpCB={clearMarkup}/>
    }
    else if(screen == 'verbs') {
        display = <VerbTraining />
    }
    else if(screen == 'home') {
        display =
            <Stack spacing={1}>
                    <Grid container spacing={2} textAlign="center">
                        <Grid item xs={6}>
                            <Card
                                style={{ height: '100%' }}
                                value='lessons'
                            >
                                <CardActionArea
                                    component="a"
                                    value={'lessons'}
                                    onClick={cardCB}
                                >
                                    <CardHeader
                                        title='Markup Exercises'
                                    />
                                    <CardContent>
                                        <Typography variant="h5" gutterBottom>
                                            Markup Full Sentences
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card
                                style={{ height: '100%' }}
                                value='lessons'
                            >
                                <CardActionArea
                                    component="a"
                                    value={'verbs'}
                                    onClick={cardCB}
                                >
                                    <CardHeader
                                        title='Verb Exercises'
                                    />
                                    <CardContent>
                                        <Typography variant="h5" gutterBottom>
                                            Verb Endings, Participles, Principal Parts
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
            </Stack>
    }

    return (
        <>
            <Typography align={"center"}>Application temporarily unavailable</Typography>
            <Typography align={"center"}>© Greek2Me {new Date().getFullYear()}</Typography>
        </>
    );


    /*return (<>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppBar darkMode={darkMode} switchTheme={switchDarkCB} lessons={lessonNames} currentLesson={lessonName} lessonCB={switchLessonCB} goHomeCB={GoHome} />
            <Container>
                <Box sx={{my: 1}}>
                    {display}
                </Box>
            </Container>
            <Typography align={"center"}>© Greek2Me {new Date().getFullYear()}</Typography>
        </ThemeProvider>
        </>
    );*/
}


