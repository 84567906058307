function decodeAnno(anno) {
    let pos = anno.charAt(0);
    let decl = anno.charAt(1);
    let number = anno.charAt(2);
    let totalWords = anno.charAt(3);
    if(totalWords == '') {
        totalWords = 1;
    }
    let role, numberBack;
    if(pos == 'V'){
        role = 'verb_f';
        if(anno.charAt(1) == 'N') {
            role = 'verb_nf';
        }
        numberBack = 'none';
        totalWords = 0;
    }
    if(pos == 'N') {
        switch(decl) {
            case 'N': role = 'noun_nom'; break;
            case 'G': role = 'noun_gen'; break;
            case 'D': role = 'noun_dat'; break;
            case 'A': role = 'noun_acc'; break;
            default: role = 'none';
        }
        switch (number) {
            case 'S': numberBack = 'singular'; break;
            case 'P': numberBack = 'plural'; break;
            default: numberBack = 'none';
        }
    }
    return {role: role, number: numberBack, numWords: totalWords};
}

function isPrep (word) {
    let preps = ["ἐν","ἐν", "πρὸς", "ἐκ", "μετὰ", "εἰς", "παρὰ", "ἐξ", "ἄχρι", "ἐπὶ","διὰ", "μετ’", "κατὰ", "περὶ", "ἀπὸ", "ἀπʼ", "ἀπ’", "πρὸ","ὀπίσω", "οὐ", "οὐκ", "οὐχ"];
    return preps.includes(word);
}

function isArticle (word) {
    let articles = ["ὁ", "τοῦ", "τῷ", "τόν", "οἱ", "τῶν", "τοῖς", "τούς", "ἡ", "τής", "τῇ", "τῇ", "τῆν", "αἱ", "ταῖς", "τάς", "τό", "τά", "τὰ", "τὴν", "τοὺς", "τῆς", "τὸν", "τὴς", "τὰς", "τὸ", "τῷ"];
    return articles.includes(word);
}

function isNegation(word) {
    let negations = ["οὐ", "οὐκ", "οὐχ"];
    return negations.includes(word);
}

function markupToJSON(lesson) {
    let tokens = lesson.split( ' ');
    let text = "";
    let words = [];
    let syntax = [];
    let trywords = [];
    let item;
    let wordAnno;
    let role, number, decode;
    let hasArticle = false;
    let nounWords;
    let hasNegation = false;
    let article, negation;
    let syntaxType;
    let syntaxLength;
    for(let i=0;i<tokens.length;i++){
        item = tokens[i];
        wordAnno = item.split('/');
        /*if(isArticle(wordAnno[0])) {
            if(i + 1 < tokens.length && !isArticle(tokens[i+1].split('/')[0]) && !isPrep(tokens[i+1].split('/')[0])) {
                hasArticle = true;
                article = wordAnno[0];
                continue;
            }
        }
        if(hasArticle) {
            wordAnno[0] = article + " " + wordAnno[0];
        }
        hasArticle = false;
        if(wordAnno[0][0] == '(') {
            nounWords.push(wordAnno[0].substring(1));
            continue;
        }*/

        if(i==tokens.length-1) {
            text += wordAnno[0];
        } else {
            text += wordAnno[0] + ' ';
        }
        if(wordAnno.length >= 2 && wordAnno[1][0] != 'S') {
            decode = decodeAnno(wordAnno[1]);
            nounWords = decode.numWords - 1;
            role = decode.role;
            number = decode.number;
            if(nounWords >= 1) {
                let npwords = wordAnno[0];
                for (let i = 0; i < nounWords; i++) {
                    npwords = words[words.length - 1 - i].text + ' ' + npwords;
                }
                wordAnno[0] = npwords;
                words.splice(words.length - nounWords, nounWords );
                trywords.splice(trywords.length - nounWords, nounWords);
            }
        }
        else {
            role = 'none';
            number = 'none';
        }
        words.push({role: role, text: wordAnno[0], number: number});
        trywords.push({role: 'none', text: wordAnno[0], number: 'none'});

        //Extract syntax

        for(const s of wordAnno.slice(1)) {
            if(s[0] == 'S') {
                if(s.length < 4) {
                    console.log('syntax code length too short')
                    continue;
                }
                syntaxType = s.substring(1,3);
                syntaxLength = s.charAt(3);
                switch (syntaxType) {
                    case 'PR': syntaxType = 'prepositional_phrase'; break
                    case 'PA': syntaxType = 'participle_phrase'; break
                    default: console.log('no syntax match')
                }
                syntax.push({groupStart: words.length - syntaxLength, groupEnd: words.length - 1,
                    granularity: 'group', role: syntaxType});
            }
        }
    }
    syntax.sort((a, b) => {return (a.groupStart != b.groupStart) ? a.groupStart - b.groupStart :
        b.groupEnd - a.groupEnd});
    //console.log({text: text, markUpAttempt: {words: trywords, syntax: []}, markupAnswer: {words: words, syntax: []}})
    return {text: text, markupAttempt: {words: trywords, syntax: []}, markupAnswer: {words: words, syntax: syntax}};
}


function GetLessons() {
    let lessons =
        [
            {
                name: "0.2",
                title: "Into to Nouns: the 2nd Masculine Paradigm",
                passages: [
                    "θεὸς/NNS sent./V",
                    "raised/V Χρίστοι./NNP",
                    "trusted/V Ἰουδαῖος./NNS",
                    "judged/V ἄγγελοι/NNP Χριστόν./NAS",
                    "κυρίους/NAP found/V ἄνθρωποι./NNP",
                    "Ἰουδαῖοι/NNP ate/V Πέτρον/NAP and/NAP Ἰάκωβον./NAP",
                    "υἱόν/NAP we see/V.",
                    "ἀνθρώπῳ/NDS you wrote/V",
                    "found/V λαῷ/NDS κύριον/NAS υἱοὶ/NNP",
                    "Παύλῳ/NDS sent/V θεός/NNS",
                    "have/V ἄνθρωποι/NNP λόγον/NAS κυρίου./NGS",
                    "υἱὸς/NNS ἀγγέλου/NGS threw/V Πιλατῷ/NDS Πέτρον./NAS",
                    "Ἰησοῦν/NAS I raised./V",
                    "exulted/V ἀγγέλους/NAP Ἰακώβου/NGS Ἰησοῦς/NNS κόσμῳ./NDS",
                ]
            },
            {
                name: "0.3",
                title: "The 1st Declension: Feminine and Masculine",
                passages: [
                    "ὄχλοι/NNP ἐξουσίαν/NAS had./V",
                    "desired/V φαρισαῖος/NNS ἀδελφοὶς/NDP ὥραν/NAS ἐξουσίας/NGS",
                    "δούλοι/NNP ἁμαρτίας/NGS saw/V θάνατον./NAS",
                    "ἐξουσίας/NAP ἐρήμου/NGS you/NNS received./V",
                    "Ἰησους/NNS spoke/V λόγους/NAP ἀγάπης./NGS",
                    "ἄρτον/NAS φωναὶ/NNP ἐξουσίας/NGS wanted./V",
                    "contained/V ἐντολαὶ/NNP λόγους/NAP χριστοῦ./NGS",
                    "Ἰησοῦ/NDS Ἡρῴδης/NNS proclaimed/V ἐντολὴν/NAS κύριου./NGS",
                    "Πέτρος/NNS found/V θάλασσαν/NAS ἐρήμου./NGS",
                    "gave/V δόξας/NAP γλῶσσα/NNS θεῷ./NDS",
                    "θεῷ/NDS γλῶσσαι/NNP ἀνθρώπων/NGP lifted/V up/V δόξας/NAP ἀγγέλου./NGS",
                    "γλῶσσαι/NNP ἀγγέλων/NGP δόξας/NAP θεοῦ/NGS spoke./V",
                    "saw/V μαθητὴς/NN ἀγάπης/NGS ὀφθαλμοὺς/NAP υἱοῦ./NGS",
                    "κυρίῳ/NDS Ἰωάννου/NGS ἀδελφοὶ/NNP wrote/V λόγον./NAS"
                ]
            },
            {
                name: "0.4",
                title: "The 2nd Neuter Declension: the Article",
                passages: [
                    "παιδία/NNP σατανοῦ/NGS spoke/V ἐντολάς./NAP",
                    "Ἰησοῦ/NDS δαιμόνια/NNP gave/V ἐξουσίαν./NAS",
                    "Ἰησοῦς/NNS διαμόνια/NAP gave/V ἐξουσίᾳ./NDS",
                    "βασιλείᾳ/NDS παιδία/NAP θεὸς/NNS raised./V",
                    "said/V ἄγγελος/NNS λόγους/NAP εὐαγγελίου/NGS κυρίου/NGS ἐκκλησίαις./NDP",
                    "said/V ἄγγελοις/NDP ἐυαγγελίου/NGS λόγου/NGS κυρίου/NGS ἐκκλησίαι./NNP",
                    "σατανᾷ/NDS μαθητὴς/NNS προφητῶν/NGP knew/V εὐαγγέλια/NAP λόγου/NGS ἁμαρτίας./NGS",
                    "σατανᾶν/NAS προφήταις/NDP μαθητοῦ/NGS knew/V εὐαγγέλια/NNP λόγου/NGS ἁμαρτίας./NGS",
                    "τὰ παιδία/NNP2 τοῦ δαιμονίου/NGS2 desired/V τὴν ψυχὴν/NAS2 τοῦ Πέτρου./NGS2",
                    "τοὺς δούλους/NAP2 τῶν θηρίων/NGP2 τῆς γῆς/NGS2 ὁ θάνατος/NNS2 ate./V",
                    "τὰ ἔργα/NAP2 τοῦ νόμου/NGS2 we judged/V τῷ Ἰωάννῃ./NDS2",
                    "τὴν φωνὴν/NAS2 ἐντολῆς/NGS τῆς δόξης/NGS2 οἱ ἄγγελοι/NNP2 τοῦ εὐαγγελίου/NGS2 wrote./V",
                    "spoke/V τὴν δικαιοσυνὴν/NAS2 τῆς βασιλείας/NGS2 ὁ κύριος/NNS2 τῶν οὐρανῶν/NGP2 τοῖς" +
                    " Ἰουδαίοις./NDP2"
                ]
            },
            {
                name: "0.5",
                title: "Intro to Verbs",
                passages: [
                    "ὁ δοῦλος/NNS2 ἔχει/V τὸν ἄρτον/NAS2 τῆς ζωῆς/NGS2 τῷ παιδίῳ./NDS2",
                    "τὸν κύριον/NAS2 τῆς ἀγάπης/NGS2 γινώσκομεν./V",
                    "βάλλουσιν/V τὸν Ἰησοῦν/NAS2 οἱ οἶκοι/NNP2 τῇ βασιλείᾳ/NDS2 τοῦ θηρίου./NGS2",
                    "βάλλει/V ἡ βασιλεία/NNS2 τοῖς οἴκοις/NDP2 τοῦ Ἰησοῦ/NGS2 τὸ θηρίον./NAS2",
                    "βάλλετε/V τὴν βασιλείαν/NAS2 τῶν οἴκων/NGP2 τῷ θηρίῶ/NDS2 τοῦ Ἰησοῦ./NGS2"
                ]
            },
            {
                name: "1.1",
                title: "Prepositions: Case and Time",
                passages: [
                    "ἐν τῇ ἡμέρᾳ/NDS2/SPR2 τοῦ κυρίου/NGS2 ἡ δόξα/NNS2 μένει/V ἐν τῇ βασιλείᾳ/NDS2 τοῦ θεοῦ./NGS2/SPR3",
                    "Φίλιππος/NNS βάλλει/V τὰ ἰμάτια/NAP2 τὴς ἁμαρτίας/NGS2 πρὸς τὸν ὄχλον./NAS2/SPR2",
                    "τοῖς μαθήταις/NDP2 ἐν τῇ ἐρήμῳ/NDS2/SPR2 λέγομεν/V τὰς ἐντολὰς/NAP2 τοῦ βιβλίου./NGS2",
                    "ὁ θεὸς/NNS2 ἐγείρει/V υἱὸν/NAS ἐκ τῆς βασιλείας./NGS2/SPR2",
                    "μετὰ τὴν ὣραν/NAS2/SPR2 εὑρίσκω/V τὸν προφήτην/NAS2 ἐν τῇ δόξῃ./NDS2/SPR2",
                    "εἰς τὸ πλοῖον/NAS2/SPR2 τοὺς οἴκους/NAP2 τῶν σημείων/NGP2 ὁ ἄγγελος/NNS2 τοῦ κυρίου/NGS2 αἴρει./V",
                    "λέγει/V τὸ εὐαγγέλιον/NAS2 τῶν ἔργων/NGP2 παρὰ τὴν θάλασσαν/NAS2/SPR2 ἡ φωνὴ/NNS2 τῶν ἀδελφῶν./NGP2",
                    "τὴν αγάπην/NAS2 ὁ ὀφθαλμὸς/NNS2 τοῦ θηρίου/NGS2 ἐξ ἐρήμου/NGS/SPR2 θέλει./V",
                    "οἱ προφήται/NNP2 τοῦ ἱεροῦ/NGS2 τῷ σαββατῳ/NDS2 παρὰ τῇ θαλάσσᾳ/NDS2/SPR2 κρίνουσιν/V Ἰησοῦν./NAS",
                    "τῶν ἐξουσίων/NGP2 τῆς βασιλείας/NGS2 τοῦ θάνατου/NGS2 ἀκούει/V ὁ υἱὸς/NNS2 τοῦ ἀνθρώπου./NGS2",
                    "ἐν τῷ προσώπῳ/NDS2/SPR2 τοῦ κυρίου/NGS2 πιστεύουσιν/V τῇ δικαιοσύνῃ/NDS2 τοῦ θεοῦ/NGS2 αἱ" +
                    " καρδίαι/NNP2" +
                    " τῶν μαθητῶν./NGP2"
                ]
            },
            {
                name: "2.1",
                title: "Contract -εω Verbs",
                passages: [
                    "φωνοῦσιν/V τὰ δαιμόνια./NNP2",
                    "Ἰησοῦ/NDS ἀκολουθῶ/V μετὰ τῶν μαθητῶν./NGP2/SPR2",
                    "παρὰ τὴν θάλασσαν/NAS2/SPR2 πλοῖον/NAS τὰ παιδία/NNP2 ζητοῦσι./V",
                    "τοῖς προφήταις/NDP2 τοῦ εὐαγγελίου/NGS2 ἀκολουθεῖ/V ὁ Ἰησοῦς/NNS2 ἄχρι τῆς ἐρήμου./NGS2/SPR2",
                    "κατὰ τὰς ἐντολὰς/NAP2 τοῦ νόμου/NGS2/SPR3 ποιεῖτε/V τὰ ἔργα/NAP2 τῆς δικαιοσύνης./NGS2",
                    "ὁ υἱος/NNS2 τοῦ Πέτρου/NGS2 θηρίῳ/NDS πιστεύει/V περὶ τῆς ἡμέρας/NGS2 τῶν Ἰουδαίων./NGP2/SPR3"
                ]
            },
            {
                name: "2.2",
                title: "The Imperfect Tense-form",
                passages: [
                    "οἱ ἄγγελοι/NNP2 ηὕρισκον/V Πέτρον./NAS",
                    "ἤθελες/V τὸ εὐαγγέλιον/NAS2 τοῦ θεοῦ./NGS2",
                    "ἐσθίουσιν/V τὰ θηρία/NNP2 τὸν κύριον./NAS2",
                    "ἠσθίεν/V τὰ θηρία/NAP2 ὁ κύριος./NNS2",
                    "ἐλέγομεν/V τῷ Ἰησοῦ./NDS2",
                    "ἐμαρτύρει/V περὶ τῆς δικαιοσύνης/NGS2 τοῦ ἱεροῦ/NGS2/SPR3 ὁ Πέτρος./NNS2",
                    "τὰ παιδία/NAP2 ἐμισοῦν/V αἱ καρδίαι/NNP2 τῶν ἀρνίων./NGP2",
                    "τῷ βιβλίῳ/NDS2 τῆς δόξης/NGS2 Σανατᾶς/NNS ἐπίστευεν./V",
                    "ἀπὸ τῆς θαλάσσης/NGS2/SPR2 τοὺς μαθητὰς/NAP2 τοῦ δαιμόνιου/NGS2 ἐφωνοῦν./V",
                    "οἱ Ἰουδαίοι/NNP2 ᾐτοῦν/V σημεῖον/NAS ἐν τῷ σαββάτῳ/NDS2/SPR2 ἀπὸ τῶν οὐρανῶν./NGP2/SPR2"
                ]
            },
            {
                name: "3.1",
                title: "Intro to Verbal Aspect; Negating",
                passages: [
                    "οὐ πείθετε/V τοὺς ὄχλους./NAP2",
                    "μαθητὰς/NAP οὐκ ἐκάλουν/V αἱ βασιλείαι./NGS2",
                    "ἐν τῇ ἡμέρᾳ/NDS2 τοῦ κυρίου/NGS2/SPR3 κρατεῖς/V τὰ ἱματία/NAP2 τῶν ἀδελφῶν/NGP2 ἀπʼ" +
                    " ἐξουσίας./NGS/SPR2",
                    "ὁ προφήτης/NGS2 οὐκ ἐδίδασκεν/V τὸ εὐαγγέλιον/NAS2 τοῦ νόμου./NGS2",
                    "εἰς τὸν τόπον/NAS2 τῶν προφήτων/NGP2 τοῦ Σατανοῦ/NGS2/SPR4 τὰ βιβλία/NAP2 οὐχ φέρομεν./V",
                    "ἐπερισσέυον/V πρὸ τῶν ὀφθαλμῶν/NGP2 τοῦ Ἰούδου/NGS2/SPR3 τὰ παιδία/NNP2 ἐν τῷ εὐαγγελίῳ./NDS2/SPR2",
                    "οὐκ ἤρχον/V τοῦ Ἰωάννου/NGS2 οἱ υἱοὶ/NNP2 τῆς δόξης/NGS2 κατὰ τὴν ὁδὸν/NAS2 τῆς ἀγάπης./NGS2/SPR3"
                ]
            },
            {
                name: "3.2",
                title: "εἰμί; Compound Verbs",
                passages: [
                    "εἶ/V ὁ Χριστὸς./NNS2",
                    "ἦν/V σάββατον./NAS",
                    "ἔρημός/NNS ἐστιν/V ὁ τόπος./NNS2",
                    "ὁ Ἰησοῦς/NNS2 ἦν/V ἐν τῇ ἐρήμῷ./NDS2/SPR2",
                    "τοὺς μαθητὰς/NAP2 τοῦ Ἰωάννου/NGS2 ἀπεστέλλετε./V",
                    "τῷ Ἰησοῦ/NDS2 προσέφερον/V μετὰ τὰς ἡμέρας/NAP2 τῆς ἁμαρτίας/NGS2/SPR3 τὰ πρόβατα./NAP2",
                    "ἦτε/V ἡ φωνὴ/NNS2 τῆς βασιλείας./NGS2",
                    "ἐστὶν/V ὁ Ἰησοῦς/NNS2 ὁ ἄγγελος/NNS2 ἀπ’ οὐρανοῦ./NGS/SPR2",
                    "ἐπὶ τοῦ μαθητοῦ/NGS2/SPR2 ἑξουσίαν/NAS τὸ τέκνον/NNS2 εἶχεν./V",
                    "τοὺς προφήτας/NAP2 τῆς ἁμαρτίας/NGS2 παρὰ τὴν θάλασσαν/NAS2 τοῦ Ἰούδου/NGS2/SPR3 ἐξεβάλλομεν./V",
                    "προσέφερον/V τὰς γραφὰς/NAP2 τοῦ εὐαγγελίου/NGS2 ἐν τῷ ἱερῷ/NDS2 μνηείων/NGS/SPR3 οἱ" +
                    " κύριοι/NNS2" +
                    " τοῦ Ἰακώβου/NGS2 τοῖς ἀδελφοῖς/NDP2 τοῦ Πιλάτου./NGS2"
                ]
            },
            {
                name: "4.1",
                title: "The Future Tense-form",
                passages: [
                    "φωνήσει/V τὸ δαιμόνιον/NAS2 ὁ Παῦλος/NNS2 τοῖς παιδίοις./NDP2",
                    "δοῦλος/NNS ἐστὶ/V ἡ ἐξουσία/NNS2 ἀπὸ τῶν οὐρανῶν./NGP2/SPR2",
                    "ἐπὶ τῇ ὁδῷ/NDS2 τῆς δικαιοσύνης/NGS2/SPR3 ὁ Ἰωάννης/NNS2 ἐκράτει/V τὸν λαόν./NAS2",
                    "τοὺς ἀνθρώπους/NAP2 ὁ Ἰησοῦς/NNS2 θεραπεύσει/V ἀπὸ τῶν ἁπαρτιῶν./NGP2/SPR2",
                    "εἰς βασιλείαν/NAS τῆς δόξης/NGS2/SPR3 ἄξετε/V μετ’ ἀρνίου./NGS/SPR2",
                    "λόγον/NAS τοῦ βιβλίου/NGS2 οἱ μαθηταὶ/NNP2 γράψουσιν/V ἐν τῇ γλώσση/NDS2 τῶν τέκνων./NGP2/SPR3",
                    "διδάξει/V ἡ ἐξουσία/NNS2 ὀπίσω τῆς ἐκκλησίας/NGS2 τῆς ἐρήμου./NGS2",
                    "μετὰ ὡραν/NAS τῆς ἐντολῆς/NGS2/SPR3 οἱ προφήται/NNP2 τοῦ θηρίου/NGS2 ὑπάξουσιν/V ἐν πλοίῳ./NDS/SPR2",
                    "ἀπήγγελλον/V τῇ βασιλείᾳ/NDS2 τοῦ εὐαγγελίου/NGS2 ἐν τοῖς ἱματίοις/NDP2 τῶν ὄχλων./NGP2/SPR3",
                    "τὸ τέκνον/NNS2 διώξει3/V τὰς ψυχὰς/NAP2 τῶν προβάτων/NGP2 τοῖς δαιμονίοις./NDP2"
                ]
            },
            {
                name: "4.2",
                title: "The Aorist Tense-form",
                passages: [
                    "ὁ Ἰησοῦς/NNS2 ἐθεράπευσεν/V τὸν κόσμον./NAS2",
                    "τοῖς ἔργοις/NDP2 τῶν παιδίων/NGP2 οὐκ ἐπιστεύσαμεν./V",
                    "ἐκαθίσεν/V ὁ Πιλᾶτος/NNS2 ἐν τῷ τοπῷ/NDS2 τῆς ζωῆς/NGS2/SPR3 τοὺς υἱοὺς/NAP2 τοῦ εὐαγγελίου./NGS2",
                    "οὐκ ἐβάπτισα/V τὸν Πιλᾶτον./NAS2",
                    "ἐδίδαξαν/V οἱ προφήται/NNP2 ἐν τοῖς λόγοις/NDP2 τῆς ἀγάπης./NGS2/SPR3",
                    "τὸν θηρίον/NAS2 οἱ ὄχλοι/NNP2 προσοίσουσιν/V διὰ τὰς ἁμαρτίας./NAP2/SPR2",
                    "τὸν λόγον/NAS2 τοῦ εὐαγγελίου/NGS2 ἐροῦσιν./V",
                    "ἤνεγκαν/V τὰ ἰμάτια/NAP2 τῷ τέκνῳ/NGS2 οἱ ἄγγελοι/NNP2 ἐν τῷ προσώπῳ/NDS2 τῶν κυρίων./NGP2/SPR3",
                    "ἐπὶ τὸ μνεμεῖον/NAS2 τοῦ θηρίου/NGS2/SPR3 ἔγραψεν/V τὸν λόγον/NAS2 ὁ ἀδελφὸς/NNS2 τοῦ Παύλου./NGS2"
                ]
            },
            {
                name: "5.1",
                title: "Adjectives",
                passages: [
                    "καθαρίσει/V ὁ κύριος/NNS2 τὰ ἀγαπητὰ τέκνα./NAP3",
                    "τοὺς αἰνωνίους νόμους/NAP3 ὁ ἀγαπητὸς μαθητὴς/NNS3 ἔγραψεν/V ὑπὸ τῆς ἐξουσίας/NGS2 τοῦ" +
                    " θεοῦ./NGS2/SPR3",
                    "τῷ δικαίῳ κυρίῳ/NDS3 προσήνεγκαν/V τὸ ἅγιον θηρίον/NAS3 οἱ προφήται./NNP2",
                    "ὁ θεὸς/NNS2 σώσει/V τοὺς ἁγίους/NAP2 εἰς τὴν βασιλείαν./NAS2/SPR2",
                    "ἐν πλοίῳ/NDS/SPR2 προσεκύνησεν/V ὁ πρεσβύτερος/NNS2 τῷ Ἰησοῦ./NDS2",
                    "ἕτερα τέκνα/NNP2 πρὸ τῆς ἡμέρας/NGS2 τοῦ κυρίου/NGS2/SPR3 τὴν αἰώνιον δόξαν/NAS3 κηρύξουσιν./V",
                    "ἐπείρασεν/V τοὺς δικαίους προφήτας/NAP3 ὁ υἱὸς/NNS2 τοῦ ἀνθρώπου/NGS2 μετὰ τῶν νόμων./NGP2/SPR2",
                ]
            },
            {
                name: "6.1",
                title: "The Imperative Mood",
                passages: [
                    "εὕρισκε/V τὸν Ἰησοῦν./NAS2",
                    "προσφέρετε/V θηρίον/NAS τῷ θεῷ./NDP2",
                    "ἀγοράζε/V τοῖς δούλοις/NDP2 τὸν ἄρτον./NAP2",
                    "ὁ Ἰωάννης/NNS2 τοῦς ὄχλους/NAP2 βάπτισατω/V ἐν τῇ θαλάσσῃ./NDS2/SPR2",
                    "τῷ κυρίῳ/NDS2 μετὰ τῶν ἀδελφῶν/NGP2/SPR2 διακονησατε./V",
                    "οἱ ἅγιοι/NNP2 μὴ καθαριζέτωσαν/V τᾶ ἱμάτια/NAP2 ὀπίσω τῶν αἰωνίων ἐκκλησίων./NGP3/SPR2",
                    "μετὰ τὴν ἡμέραν/NAS2 τοῦ κυρίου/NGS2/SPR3 τὸ ἀγαπητὸν εὐαγγέλλιον/NAS3 μόνος υἱὸς/NNS2 ἐν τῷ" +
                    " σαββατῳ/NDS2/SPR2 κραξάτω./V",
                    "τὰς ψυχὰς/NAP2 τῆς δικαιοσύης/NGS2 προσενεγκάτωσαν/V οἱ ὄχλοι/NNP2 ἐν τῷ προσώπῳ/NDS2 τοῦ" +
                    " πονηροῦ πρεσβυτέρου./NGS3/SPR3"
                ]
            },
            {
              name: "6.2",
              title: "Introduction to Participles",
                passages: [
                   "ὁ λύων/VN κύριος/SPA3 ἤγγισεν/V τοῖς ἔχθροις δούλοις./NDP3",
                    "ὁ Ἰησοῦς/NNS2 ἐλάλησεν/V τοῖς πρεσβυτέροις/NDP2 τὸν θεραπεύοντα/VN λόγον/SPA3 χωρὶς τοῦ" +
                    " εὐαγγελίου./NGS2/SPR2",
                    "δοξάσουσιν/V οἱ γραψαντες/VN προφήται/SPA3 κατὰ τὰς γλῶσσας/NAP2 τῶν ἀγγέλων/NGP2/SPR3 τα" +
                    " τέκνα./NAP2",
                    "ὁ Πέτρος/NNS2 μετὰ τοῦ λόγου/NGS2/SPR2 τοῦ εὐαγγελίου/NGS2 τοῦς πιστεύσαντας/NAP2 παρακαλείτω./V",
                    "ἀποστελλουσιν/V τοὺς ὄχλους/NAP2 τῷ βαπτίζοντι/VN Ἰωάννῳ/SPA3 αἱ δίκαιαι/NNP2 βασιλείαι./V",
                    "τὰ δαιμονία/NNP2 πειρασάτωσαν/V τὸν τὰ τέκνα/NAP2 θεραπεύσαντα/VN μαθητήν./SPA4",
                    "ἐκήρυξεν/V ὁ τὴν ὁδὸν/NAS2 τῷ κυρίῳ/NDS2 ἑτοίμασας/VN προφήτης/SPA5 ἐν τῷ ἐρήμῳ./NDS2/SPR2",
                    "ἔπραξεν/V ἡ βασιλεία/NNS2 τοῦ τῷ κυρίῳ/NDS2 τὸ εὐαγγέλιον/NAS2 διδάσκοντος/VN οἴκου/SPR5 ἐν τῇ" +
                    " ἡμέρᾳ/NDS2 τῆς ἁμαρτίας/NGS2 τὰ" +
                    " αἰώνια ἔργα./NAP3/SPR4",
                    "ἐκαθαρισεν/V τοὺς ἐπὶ τὸ πρόσωπον/NAS2 τοῦ παιδίου/NGS2/SPR3 τοὺς λογοὺς/NAP2 τοῦ θεοῦ/NGS2" +
                    " γράψαντας/VN κυρίους/SPA8 ἡ ἑτέρα" +
                    " ἐξουσία/NNS3 κατὰ τὸν τοὺς ἁγίους/NAP2 καλουντα/VN προφήτην./SPR5/SPA4"
                ]
            },
            {
                name: "7.1",
                title: "1st and 2nd Person Pronouns",
                passages: [
                    "ὁ θεὸς/NNS2 μετενόησεν/V ἐκ τῶν λόγων/NGP2 τοῦ υἱοῦ/NGS2/SPR3 ἡμῖν./NDS",
                    "ἐγώ/NNS εἰμι/V ὁ τὸν Ἰησοῦν/NAS2 δοξάσας./VN/SPA3",
                    "ἀπέστελλον/V πρὸ προσώπου/NGS σου/NGS/SPR3 τὸν ἄγγελόν/NAS2 μου/NGS ἐγώ./NNS",
                    "τὸν λόγον/NAS2 πράξει/V ἔξω τῆς γῆς/NAP2 τῆς δικαιοσύνης/NGS2 ὑμῶν/NGP/SPR4 ὁ Πέτρος./NNS2",
                    "οὐχ εὑρίσκουσίν/V με/NAS οἱ τὰς ἐντολὰς/NAP2 κηρύξαντες/VN μαθηταὶ/SPA4 ἐν τῇ" +
                    " θαλάσσῃ./NDS2/SPR2",
                    "ὑπέταξεν/V ἡ ἐξουσία/NNS2 τοῦ μὴ τοὺς προφήτας/NAP2 ἐν τῷ σαββάτῳ/NDS2/SPR2 θεραπευσαντος/VN" +
                    " δούλου/SPA7 ἐν τῇ ἡμέρᾳ/NDS2 τῆς βασιλείας/NGS2 τῶν οὐρανῶν/NGP2/SPR4 ὑμᾶς./NAS"
                ]
            },
            {
                name: "7.2",
                title: "3rd Person Pronouns",
                passages: [
                    "τοὺς αἰωνίους προφήτας/NAP3 αὐτὸς/NNS ἐξέβαλλεν/V ἐκ τοῦ πλοίου./NGS2/SPA2",
                    "οὐκ ἐκάθισεν/V ὁ πίστευσας ἀδελφὸς/NNP ἐν αὐτῇ./NDS/SPR2",
                    "ὁ Ἰησοῦς/NNS2 ἐδίδαξεν/V αὐτοὺς/NAP μετ’ ἐξουσίας./NGP/SPR2",
                    "ὁ βαπτίζων/NNS2 τὸ εὐαγγελίον/NAS2 ἀυτῆς/NGS ἀπέστελλεν/V εἰς τὴν γὴν./NAS2/SPR2",
                    "ἐφύλαξα/V τὰ ἔργα/NAP2 τοῦ θεοῦ/NGS2 αὐτοῦ./NGS",
                    "μὴ δόξασον/V τὸν προφήτην/NAS2 ἐν τῇ ἐκκλησίᾳ/NDS2 αὐτοῦ./NGS/SPR3",
                    "διηκονῆσεν/V ἡ δίκαια/NNS2 τοῖς αἰωνίοις οἴκοις/NDP3 αὐτῆς/NGS ἐν τῷ τὴν ψυχήν/NAS2 αὐτῶν/NGS" +
                    " πειράσαντι/VN κόσμῳ./SPA5/SPR6",
                    "ἕξει/V ὁ τὸ εὐαγγέλιον/NAS2 εἰς τοὺς ἐχθροὺς/NAP2/SPR2 κήρυξας/SPA5 μετ’ αὐτοῦ/NGS/SPR2 τοὺς" +
                    " μαθητὰς/NAP2 αὐτοῦ/NGS ἐν τῇ καινῇ/NDS2 ὥρᾳ./SPR3"
                ]
            },
            {
                name: "8.1",
                title: "Conjunctions and Clause Relationships",
                passages: [
                    "οἱ λαοὶ/NNP2 τῆς γῆς/NGS2 τὸν πονηρὸν πρεσβύτερον/NAS3 προσεκύνησαν/V ἀλλὰ μετανοήσουσιν/V τὰ" +
                    " τέκνα/NNP2 μοῦ/NGS ἀπὸ τῶν ἔργων/NGP2 τοῦ θανάτου./NGS2/SPA3",
                    "εἰ ἑτοιμάζει/V ὁ βαπτίζων/VN Ἰωάννης/SPA3 τὴν ὁδὸν/NAS2 τοῦ κυρίου,/NGS2 ἐγγίσει/V τοῖς" +
                    " ἀνθρώποις/NDP2 ἡ βασιλεία/NNS2 τοῦ θεοῦ./NGS2",
                    "τὸ σημεῖον/NAS2 τῶν δικαίων ἡμέρων/NGP3 ἄξουσιν/V αἱ ἐντολαὶ/NNP2 τοῦ κυρίου/NGS2 καί οἱ" +
                    " οὐρανῶν/NNP2 ἐλπίζουσιν/V τὴν δόξαν/NAS2 τοῦ θεοῦ./NGS2",
                    "τὰ δὲ τέκνα/NNP3 ἐξέβαλλον/V εἰς τὴν ἐρήμον/NAS2/SPR2 τοὺς καινοὺς ἀγγέλους/NAP3 καὶ ἤσθιεν/V" +
                    " θηρίον/NNS ἀυτούς./NAP",
                    "ὁ οὖν Ἀνδρέας/NNS3 ἐπιστευσεν/V ὅτι ἐν τῷ σαββάτῳ/NDS2/SPR2 ἑτοιμάσουσιν/V ὁ Πιλᾶτος καὶ οἱ" +
                    " ἀδελφοὶ/NNP5 αὐτοῦ/NGS τὸ πρῶτον ἱμάτιον./NAS3",
                    "οὐ ἐθαυμάσσεν/V τοὺς ὄχλους/NAP2 τοῦ Ἰούδα/NGS2 ὁ Ἰησοῦς/NNS2, τὰ γὰρ ἔργα/NAP3 τοῦ θεοῦ/NGS2" +
                    " αὐτοῦ/NGS οὐκ ἔπρασσον./V",
                    "ὁ Ἰησοῦς/NNS2 ἐκήρυξεν/V τὸ εὐαγγέλιον/NAS2 τοῖς ἀδελφοῖς/NDP2 βαπτίζων/VN ἐν τῇ" +
                    " θαλάσσῃ/NDS2/SPR2 αὐτοὺς./NAP",
                    "ἔκραξεν/V ὁ ἀγαπητὸς μαθητὴς/NNP3 λέγων/VN ὅτι· τὸ πλοῖον/NAS2 ὀπίσω τοῦ μνημείου/NGS2/SPR2" +
                    " εὑρίσκω./V",
                    "ἐδιδαξατε/V τοὺς ἀγαθοὺς προφήτας/NAP3 γράφοντες/VN πρὸ αὐτῶν/NGP/SPR2 τοὺς λόγους/NAP2 τῆς" +
                    " ἐξουσίας./NGS2",
                    "ὁ γὰρ Ἰησοῦς/NNS3 σώσει/V τὸν τὰ κακὰ ἔργα/NAP4 πράξαντα/VN ἁμαρτωλὸν/SPA3 ἐγείρων/VN αὐτὸν/NAS" +
                    " ἐν τῇ ἡμέρᾳ/NDS2 τοῦ κυρίου./NGD2/SPR3"
                ]
            },
            {
                name: "8.2",
                title: "The Passive and Middle Voices",
                passages: [
                    "ἐκ τῆς γῆς/NGS2 ἡμῶν/NGS/SPR3 προφήτης/NNS οὐκ ἐγείρεται/V",
                    "διηκονούμεθα/V ὑπὸ τοῦ κηρύξαντος/VN ἀδελφοῦ/SPR4/SPA3 ἐν τῷ σαββάτῳ./NDS2/SPR2",
                    "λύεται/V τὸ καινὸν θηρίον/NNS3 τῇ βασιλείᾳ/NDS2 τῶν οὐρανῶν./NGP2",
                    "τοὺς ὀχλοὺς/NAP2 θεραπεύσω/V ὑπὸ γὰρ τοῦ Ἰωάννου/NGS2/SPR3 αὐτοὶ/NNP βαπτίζονται./V",
                    "οἱ προφήται/NNP2 τοὺς οἴκους/NAP2 τῆς αἰωνίου βασιλείας/NGP3 παρεκάλεσαν/V, ἀλλὰ ἐπιστεύοντο/V" +
                    " οἱ τὰ ἱματία/NAP2 καθαρίσαντες/VN δούλοι/SPA4 μετὰ τοῦ εὐαγγελίου/NGS2 τοῦ νόμου./NGS2/SPR3",
                    "τὸ δὲ παιδίον/NNS3 τοῖς ἀγγέλοις/NDP2 ἔλεγεν/V ὅτι· ἐκβάλλεσθε/V εἰς τὴν ἐρήμον/NGS2" +
                    " μου./NGS/SPA3",
                    "καὶ εὑρίσκεται/V ὑπὸ τῶν ζητούντων/VN αὐτήν/NAS/SPA3/SPR4",
                    "διὸ ἐπεκάλεσαν/V ὑμὰς/NAP οἱ ἀδελφοὶ/NNP2 λέγων·/VN μὴ πειράζεσθε/V ὑπὸ τοῦ σατανοῦ/NGS2/SPR2" +
                    " ἀλλὰ ἐν" +
                    " ταῖς ὁδοῖς/NDP2 τοῦ κυρίου/NGS2/SPR3 περιπατήσατε./V"
                ]
            },
            {
                name: "9.1",
                title: "Middle-Voiced Lexemes",
                passages: [
                    "οἱ μαθηταὶ/NNP2 τοῦ Παύλου/NGS2 εὐαγγελίζονται/VF ἔξω τοῦ ἱεροῦ./NGS2/SPR2",
                    "οὐ δέξομαι/VF ἐκ τοῦ οἴκου/NGS2 σου/NGS/SPR3 τὰ παιδία/NAP2 ὅτι αὐτοὺς/NAP σώσω./VF",
                    "ἐργασαι/VF τὰ δίκαια ἔργα/NAP3 τοῦ θεοῦ/NGS2 ὑπὸ γὰρ τοῦ νόμου/NGS2 τοῦ κυρίου/NGS2" +
                    " ὑμῶν/NGP/SPR5 οἰκοδομήσομεν./VF",
                    "τὸ θηρίον/NAS2 φάγεσθε/VF πρὸ προσώπου/NGS τῶν ἀγγέλων./NGP2/SPR3",
                    "ἐσθίεσθε/VF ὑπο τοῦ θηρίου/NGS2/SPR2 πρὸ προσώπου/NGS τῶν ἀγγέλων./NGP2/SPR3",
                    "ὅτε ἔρχονται/VF ὁ Ἰησοῦς καὶ οἱ μαθηταὶ/NNP5 αὐτοῦ/NGS τῇ γῇ/NDS, λήμψονται/VF αὐτοὺς/NAP οἱ" +
                    " διώξαντες./NNP2",
                    "εὐηαγγελίσατο/VF ὁ προφήτης/NNP2 τοὺς ὄχλους/NAP2 τοῦ βαπτίζοντες Ἰωάννου/NGS3 λεγῶν·/VN εἰ" +
                    " υἱοὶ/NNS τοῦ οὐρανοῦ/NGS2" +
                    " ἐστε/VF, ἀνοίξετε/VF τὰ μνημεῖα/NAP2 καὶ ἀναβήσονται/VN αἱ ζωαὶ./NNP2"
                ]
            },
            {
                name: "9.2",
                title: "Liquid Stems; 2nd Aorists",
                passages: [
                    "ὁ δὲ Ἰησοῦς/NNS3 ἀνοίξει/VF τὰς καρδίας/NAP2 τῶν οὐρανῶν/NGP2 τοῖς ἀνθρώποις/NDP2 αὐτοῦ./NGS",
                    "ὑμεῖς/NNP τοὺς ἁγίους/NAP2 κατὰ τὰ ἔργα/NAP2 αὐτῶν/NGP/SPR3 κρινεῖτε./VF",
                    "ὅτε προσεύχονται/VF οἱ ἀδελφοὶ/NNP2 τῷ θεῷ/NDS2 ἡμῶν,/NGP μενοῦσιν/VF ἐν τῇ αἰωνίῳ" +
                    " γῇ./NDS3/SPR2",
                    "τὰ γὰρ τέκνα/NNP3 εἴπον/VF ὅτι ἤλθεν/VF ὁ Ἰησοῦς/NNS2 πρὸς ἡμάς/NAP/SPR2, ἀλλ’ αὐτὸν/NAS ὁ" +
                    " ὀχλος/NNS2 οὐκ ἐλαβεν./VF",
                    "εἰ εἶ/VF υἱὸς/NNS τοῦ θεοῦ/NGS2, εἰπὲ/VF τῷ ὄχλῳ/NDS2· ἀσπάσασθε/VF τοὺς μαθητάς./NAP2",
                    "δίο καυξόμεθα/VF ὅτι εἶ/VF ἡ βασιλεία/NNS2 τῶν Ἰουδαίων./NGP2",
                    "τὴν ἀγαπητὴν βασιλείαν/NAS3 ἐδίωξαν/VF οἱ ἀδελφοί/NNP2, ἀλλ’ οἱ κλαίσαντες προφήται/NNP3 ἐν τοῖς" +
                    " ἔργοις/NDP2 αὐτῶν/NGP/SPR3 οὐχ ἥμαρτον./VF",
                    "ὁ υἱὸς/NNS2 τοῦ θεοῦ/NGS2 τοὺς τὸ θηρίον/NAS2 διακονήσαντας/VN/SPA3 ἔκρινεν/VF καὶ αὐτοὺς/NAP" +
                    " ἔπεμψεν/VF εἰς τὰ μνημεία/NAP2 αὐτῶν./NGP/SPR3"
                ]
            },
            {
                name: "10.1",
                title: "Future and Aorist Passives",
                passages: [
                    "ὁ δὲ Ἰησοῦς/NNS3 ἔλεγεν/VF τῷ ἀνθρώπῳ‧/NDS2 θέλω,/VF καθαρίσθητι./VF",
                    "λογιζόμεθα/VF οὖν ὅτι ἐπιστεύθησαν/VF οἱ λόγοι/NNP2 τοῦ θεοῦ/NGS2 τοῖς ἁγίοις./NDP2",
                    "Ἀπεκρίθη/VF οὖν αὐτῷ/NDS ὁ ὄχλος·/NNS2 ἡμεῖς/NNP ἠκούσαμεν/VF ἐκ τοῦ νόμου/NGS2/SPR2 ὅτι ὁ" +
                    " χριστὸς/NNS2 μένει/VF εἰς τὸν αἰῶνα./NAS2/SPR2",
                    "ὅτε προσῆλθεν/VF τὸ τέκνον/NNS2 τῇ θαλάσσῃ,/NDS2 ἡ δικαιοσύνη/NNS2 τοῦ ἱεροῦ/NGS2 γνωσθήσεται/VF" +
                    " ἐν τῇ βασιλείᾳ/NDS2 αὐτοῦ./NGS/SPR3",
                    "τὰ παιδία/NNP2 συνήχθησαν/VF ὑπὸ τοῦ προφήτου/NGS2/SPR2 ἀλλʼ ἄγγελος/NNS ἐσώθη./VF",
                    "ὁ/NNS δὲ λέγει/VF αὐτοῖς·/NDP ἐγώ/NNS εἰμι·/VF μὴ φοβεῖσθε./VF",
                    "λημφθητωσαν/VF οἱ μαθηταὶ/NNP2 τοῦ ἀγαγοντες/VN προφήτου/NGS ὑπὸ τῶν οἰκίων/NGP2 τῆς γῇ./NGS2/SPR3"
                ]
            },
            {
                name: "10.2",
                title: "Feminine and Neuter Active Participles",
                passages: [
                    "αἱ ἀκούσασαι/NNP2 ἔκριναν/VF τοὺς ἐχθροὺς υἱούς/NAP3 αὐτῶν./NGS",
                    "ὑπὸ τῆς τὰ παιδία/NAP2 λαβούσης/VN βασιλείας/SPA4/SPR5 οἱ φαρισαίοι/NNP2 διωχθήσονται./VF",
                    "τὰ κλαίσαντα παιδία/NAP3 ἡμῶν/NGS ἀσπάσεσθε/VF εἰ ἀπὸ τῆς ἐρήμου/NGS2/SPR2 ἐξήλθον./VF",
                    "οἱ δὲ κυρίοι/NNP3 εἴπον/VF τοῖς μισησάσαις·/NDP2 τὸν δίκαιον δοῦλον/NAS3 μὴ βλεψατε./VF",
                    "γνόντες/VN τὸν θεὸν,/NAS2 οὐκ αὐτὸν/NAS ὡς θεὸν/NAS ἐδόξασαν/VF ἢ ηὐχαρίστησαν./VF",
                    "ἤκουσαν/VF οἱ ἐκ τῶν Φαρισαίων/NGP2/SPR2 μετ᾽ αὐτοῦ/NGS/SPR2 ὄντες/SPA6 καὶ εἶπον/VF αὐτῷ·/NDS" +
                    " οὐ" +
                    " καὶ ἡμεῖς/NNP τυφλοί/NNP ἐσμεν;/VF",
                    "ὁ μὴ μετ’ ἐμοῦ/NGS/SPR2 ὢν/VF/SPA5 κατ’ ἐμοῦ/NGS/SPR2 ἐστιν./VF",
                    "ἐμαρτύρησεν/VF ὁ οἶκος/NNS2 του Ἀνδρέου/NGS2 τῷ ἀγάθῳ κυρίῳ/NDS3 ὤν/VN ἔμπροσθεν τῆς αὐτὸν/NAS" +
                    " διωξάσης/VN ἐξουσίας./SPA4/SPR5",
                    "ἐκήρυξεν/VF ἡ ἐν τῇ ἐρήμῳ/NDS2/SPR2 κράξασα/VF φωνὴ/SPA5 τὴν βασιλείαν/NAS2 λέγουσα/VN ὅτι· τὰ" +
                    " παιδία/NAP2 τὴς ἀγάπης/NGS2 γένεσθε./VF",
                    "τὸ τέκνον/NNS2 τῷ ἔλθοντι θηρίῳ/NDS3 προσεκυνήσεν/VF δοκοῦν/VN ὅτι ὑπὸ τῶν ἁγίων ὄχλων/NGP3/SPR2" +
                    " τηρηθήσεται./VF"
                ]
            },
            {
                name: "11.2",
                title: "Demonstrative Pronouns; Adjective Positions",
                passages: [
                    "καὶ φωνὴ/NNS ἐγένετο/VF ἐκ τῆς νεφέλης/NGS2/SPR2 λέγουσα·/VN οὗτός/NNS ἐστιν/VF ὁ υἱός/NNS2" +
                    " μου...,/NGS αὐτοῦ/NGS ἀκούετε/VF",
                    "Καὶ μετὰ ταῦτα/NAP/SPR2 περιεπάτει/VF ὁ Ἰησοῦς/NGS2 ἐν τῇ Γαλιλαίᾳ/NDS2/SPR2",
                    "καὶ ἐξῆλθεν/VF ὁ λόγος οὗτος/NNS3 ἐν ὅλῃ τῇ Ἰουδαίᾳ/NDS3/SPR2 περὶ αὐτοῦ/NGS/SPR2",
                    "μετὰ δὲ τούτο/NAS/SPR3 εἶπεν/VF ὁ Ἰησοῦς/NNS2 τοῖς πλήθοις·/NDP2 δοξασατε/VF τὸν θεὸν/NAS2 ὑμῶν/NGP" +
                    " μετὰ φόβου./NGS/SPR2",
                    "καὶ κηρυχθήσεται/VF τοῦτο τὸ εὐαγγέλιον/NNS3 τῆς βασιλείας/NGS2 ἐν ὅλῃ τῇ κόσμῳ./NDS3/SPR2",
                    "δίκαιοι/NNP οἱ ἐπὶ τῷ θρόνῳ/NDS2 δούλου/NGS/SPR3 καθίσαντες./VN/SPA5",
                    "ἐπεγνωμεν/VF ὅτι ἥμαρτεν/VF ὁ ὄχλος ὅλος/NNS3 κατὰ τοῦ νόμου./NGS2/SPR2",
                    "με/NAS εὑρήσει/VF οὗτος ὁ σκότος/NNS3 ὀπίσω τοῦ θρόνου./NGS2/SPR2"
                ]
            }
            ];
    return lessons;
}

export function GetLesson(lessonName) {
    let lessons = GetLessons();
    let lesson = lessons.find(element => element.name == lessonName);
    if(lesson == undefined) return [markupToJSON("No lessons for " + lessonName)];
    let annotations =  lesson.passages.map((item, index) => {
        return markupToJSON(item);
    });
    return annotations;
}

export function GetLessonNames() {
    let lessons = GetLessons();
    let names =  lessons.map((item, index) => {
        return item.name;
    });
    return names.sort(function(a,b) { return a - b;}).reverse();
}

export function GetLessonNameandTitles() {
    let lessons = GetLessons();
    let names =  lessons.map((item, index) => {
        return ({name: item.name, title: item.title});
    });
    return names.sort().reverse();
}
