function getNounEndings(declension) {
    switch (declension) {
        case '2M':
            return ['ος', 'ου', 'ῳ', 'ον', 'οι', 'ων', 'οις', 'ους'];
        case '1FV':
            return ['', '', '', '', '', '', '', ''];
        case '1FC':
            return ['', '', '', '', '', '', '', ''];
        case '1FS':
            return ['', '', '', '', '', '', '', ''];
        case '1M':
            return ['', '', '', '', '', '', '', ''];
        case '2N':
            return ['', '', '', '', '', '', '', ''];
    }
    return ['', '', '', '', '', '', '', ''];
}

function getCaseIndex(role) {
    let cases = ['NS', 'GS', 'DS', 'AS', 'NP', 'GP', 'DP', 'AP'];
    return cases.indexOf(role);
}

function getStem(lexRoot, declension) {
    let trunc;
    switch (declension) {
        case '2M':
            trunc = 2;
            break;
        case '1FV':
            trunc = 1;
            break;
        case '1FC':
            trunc = 1;
            break;
        case '1FS':
            trunc = 1;
            break;
        case '1M':
            trunc = 2;
            break;
        case '2N':
            trunc = 2;
            break;
    }
    return lexRoot.substring(0, lexRoot.length-trunc);
}

export function getDeclension(lexRoot, declension, role) {
    let stem = getStem(lexRoot, declension);
    let endings = getNounEndings(declension);
    let index = getCaseIndex(role);
    let ending = endings[index];
    return stem + ending;
}

export function getNouns() {
    let twoM;
    twoM = [
        'ἄγγελος',
        'ἀδελφός',
        'ἄνθρωπος',
        'ἄρτος',
        'δοῦλος',
        'θάνατος',
        'Ἰουδαῖος',
        'κόσμος',
        'κύριος',
        'λαός',
        'λόγος',
        'νόμος',
        'οἶκος',
        'οὐρανός',
        'ὀφθαλμός',
        'ὄχλος',
        'τόπος',
        'υἱός',
        'φαρισαῖος',
    ];
    /*twoM.map((item,index) => {
        let cases = ['NS', 'GS', 'DS', 'AS', 'NP', 'GP', 'DP', 'AP'];
        cases.map((item2, index2) => {
            console.log(getDeclension(item, '2M', item2))
        });
    });*/
    return twoM.map((item,index) => {
        return {'word': item, 'decl': '2M'};
    });
}

export function GetVerbs() {
    return [
    "αἴρω",
    "ἀκούω",
        "βάλλω",
    "βλέπω",
    "γινώσκω",
    "γράφω",
    "ἐγείρω",
    "ἐσθίω",
    "εὑρίσκω",
    "ἔχω",
    "θέλω",
    "κρίνω",
    "λαμβάνω",
    "λέγω",
    "μένω",
    "πιστεύω",
        'αἰτέω',	'ἀκολουθέω',	'δέω',	'δοκέω',	'εὐλογέω',	'ζητέω',	'θεωρέω',	'καλέω',	'κρατέω',	'λαλέω',	'μαρτυρέω',	'μισέω',	'ποιέω',	'τηρέω',	'φοβέω',	'φωνέω',
];
}

export function GetPrincipalPartsTest() {
    return [
        ["Pres", "Future", "Aorist", "Perf/Pluperf Act", "Perf/Pluperf MP", "Aorist/Future Pas"],
        ["λύω", "λύσω", "ἔλυσα", "λέλυκα", "λέλυμαι", "ἐλύθην"],
        ["ποιέω", "ποιήσω", "ἐποίησα", "πεποίηκα", "πεποίημαι", "ἐποιήθην"],
    ]
}

export function GetPrincipalParts() {
    return [
        ["Pres", "Future", "Aorist", "Perf/Pluperf Act", "Perf/Pluperf MP", "Aorist/Future Pas"],
        ["λύω",	"λύσω",	"ἔλυσα",	"λέλυκα",	"λέλυμαι",	"ἐλύθην"],
        ["ποιέω",	"ποιήσω",	"ἐποίησα",	"πεποίηκα",	"πεποίημαι",	"ἐποιήθην"],
        ["κηρύσσω",	"κηρύξω",	"ἐκήρυξα",	"(κεκήρυχα)",	"-κεκήρυγμαι",	"ἐκηρύχθην"],
["εἰμί",	"ἔσομαι",	"ἤμην (impf. not aorist)",	"------",	"------",	"------"],
["ἀγγέλλω",	"-ἀγγέλω",	"-ἤγγειλα",	"-ἤγγελκα",	"-ἤγγελμαι",	"-ἠγγέλην"],
["ἄγω",	"ἄξω",	"ἤγαγον",	"------",	"-ἤγμαι",	"ἤχθην"],
["αἴρω",	"ἀρῶ",	"ᾖρα",	"ἦρκα",	"ἤρμαι",	"ἤρθην"],
["ἀκούω",	"ἀκούσω",	"ἤκουσα",	"ἀκήκοα",	"------",	"ἠκούσθην"],
["ἁμαρτάνω",	"ἁμαρτήσω",	"ἥμαρτον (IND)",	"ἡμάρτηκα",	"------",	"------"],
["ἀνοίγω",	"ἀνοίξω",	"ἤνοιξα",	"ἀνέῳγα",	"ἀνέῳγμαι",	"ἠνοίχθην"],
["-βαίνω",	"-βήσομαι",	"-έβην",	"-βέβηκα",	"------",	"------"],
["βάλλω",	"βαλῶ",	"ἔβαλον",	"βέβληκα",	"βέβλημαι",	"ἐβλήθην"],
["γίνομαι",	"γενήσομαι",	"ἐγενόμην",	"γέγονα",	"γεγένημαι",	"ἐγενήθην"],
["γινώσκω",	"γνώσομαι",	"ἔγνων",	"ἔγνωκα",	"ἔγνωσμαι",	"ἐγνώσθην"],
["ἐγείρω",	"ἐγερῶ",	"ἤγειρα",	"------",	"ἐγηγέρμαι",	"ἠγέρθην"],
["εὑρίσκω",	"εὑρήσω",	"εὗρον",	"εὕρηκα",	"εὕρημαι",	"εὑρέθην"],
["θέλω",	"θελήσω",	"ἠθέλησα",	"------",	"------",	"ἠθελήθην"],
["-θνῄσκω",	"-θάνουμαι",	"-ἔθανον",	"τέθνηκα",	"------",	"------"],
["καλέω",	"καλέσω",	"ἐκάλεσα",	"κέκληκα",	"κέκλημαι",	"ἐκλήθην"],
["κρίνω",	"κρινῶ",	"ἔκρινα",	"κέκρικα",	"κέκριμαι",	"ἐκρίθην"],
["λαμβάνω",	"λήμψομαι",	"ἔλαβον",	"εἴληφα",	"-εἴλημμαι",	"ἐλήμφθην"],
["πίνω",	"πίομαι",	"ἔπιον",	"πέπωκα",	"------",	"-επόθην"],
["-στέλλω",	"-στελῶ",	"-έστειλα",	"-έσταλκα",	"-έσταλμαι",	"-εστάλην"],
["σῴζω",	"σώσω",	"ἔσωσα",	"σέσωκα",	"σέσῳσμαι",	"ἐσώθην"],
["ἔρχομαι",	"ἐλεύσομαι",	"ἤλθον",	"ἐλήλυθα",	"------",	"------"],
["ἐσθίω",	"φάγομαι",	"ἔφαγον",	"------",	"------",	"------"],
["ἔχω",	"ἕξω",	"ἔσχον",	"ἔσχηκα",	"------",	"------"],
["λέγω",	"ἐρῶ",	"εἶπον",	"εἴρηκα",	"εἴρημαι",	"ἐρρήθην"],
        ["φέρω",	"οἴσω",	"ἤνεγκα",	"-ἐνήνοχα",	"------",	"ἠνέχθην"]
        ];
}

export function GetParticiples() {
    return [
    ["element",	"M.Pres.Act",	"M.Aor.Act",	"F.Pres.Act",	"F.Aor.Act",	"N.Pres.Act",	"N.Aor.Act",	"M.Pres.MP",	"F.Pres.MP",	"N.Pres.MP",	"M.Aor.P",	"F.Aor.P",	"N.Aor.P",	"M.ειμι",	"F.ειμι",	"N.ειμι"],
        ["NS",	"ων",	"σας",	"ουσα",	"σασα",	"ον",	"σαν",	"ομενος",	"ομενη",	"ομενον",	"θεις",	"θεισα",	"θεν",	"ὤν",	"οὖσα",	"ὄν"],
        ["GS",	"οντος",	"σαντος",	"ουσης",	"σασης",	"οντος",	"σαντος",	"ομενου",	"ομενης",	"ομενου",	"θεντος",	"θεισης",	"θεντος",	"ὄντος",	"οὔσης",	"ὄντος"],
        ["DS",	"οντι",	"σαντι",	"ουσῃ",	"σασῃ",	"οντι",	"σαντι",	"ομενῳ",	"ομενῃ",	"ομενῳ",	"θεντι",	"θεισῃ",	"θεντι",	"ὄντι",	"οὔσῃ",	"ὄντι"],
        ["AS",	"οντα",	"σαντα",	"ουσαν",	"σασαν",	"ον",	"σαν",	"ομενον",	"ομενην",	"ομενον",	"θεντα",	"θεισαν",	"θεν",	"ὄντα",	"οὖσαν",	"ὄν"],
        ["NP",	"οντες",	"σαντες",	"ουσαι",	"σασαι",	"οντα",	"σαντα",	"ομενοι",	"ομεναι",	"ομενα",	"θεντες",	"θεισαι",	"θεντα",	"ὄντες",	"οὖσαι",	"ὄντα"],
        ["GP",	"οντων",	"σαντων",	"ουσων",	"σασων",	"οντων",	"σαντων",	"ομενων",	"ομενων",	"ομενων",	"θεντων",	"θεισων",	"θεντων",	"ὄντων",	"οὐσῶν",	"ὄντων"],
    ["DP",	"ουσιν",	"σασιν",	"ουσαις",	"σασαις",	"ουσιν",	"σασιν",	"ομενοις",	"ομεναις",	"ομενοις",	"θεισι(ν)",	"θεισαις",	"θεισι(ν)",	"οὖσιν",	"οὔσαις",	"οὖσιν"],
    ["AP",	"οντας",	"σαντας",	"ουσας",	"σασας",	"οντα",	"σαντα",	"ομενους",	"ομενας",	"ομενα",	"θεντας",	"θεισας",	"θεντα",	"ὄντας",	"οὔσας",	"ὄντα"]
        ]
}

export function GetVerbEndings() {
    return [
        ["Person",	"Pre.Act.Ind.",	"Imp.Act.Ind",	"Fut.Act.Ind",	"Aor.Act.Ind",	"Pres.Act.Imp",	"Aor.Active.Imp",	"ειμι Pres",	"ειμι Imp",	"Pres.M/P.Ind",	"Imp.M/P.Ind",	"Fut.M.Ind",	"Aor.M.Ind",	"Pres.M/P.Imp",	"Aor.M.Imp",	"Fut.P.Ind",	"Aor.P.Ind",	"Aor.P.Imp",	"Per.Act.Ind.",	"Per.M/P.Ind",	"Plu.Active.Ind",	"Plu.M/P.Ind",	"γινωσκω.Aor.Act.Ind",	"γινωσκω.Aor.Act.Imp"],
        ["1S",	"ω",	"ε + ον",	"σω",	"ε + σα",	"------",	"------",	"ειμι",	"ημην",	"ομαι",	"ε + ομην",	"σομαι",	"ε + σαμην",	"------",	"------",	"θησομαι",	"ε + θην",	"------",	"dup + κα",	"dup + μαι",	"ε + dup + κειν",	"ε + dup + μην",	"ἔγνων",	"------"],
        ["2S",	"εις",	"ε + ες",	"σεις",	"ε + σας",	"ε",	"σον",	"ει",	"ης",	"ῃ",	"ε + ου",	"σῃ",	"ε + σω",	"ου",	"σαι",	"θησῃ",	"ε + θης",	"θητι",	"dup + κας",	"dup + σαι",	"ε + dup + κεις",	"ε + dup + σο",	"ἔγνως",	"γνῶθι"],
        ["3S",	"ει",	"ε + εν",	"σει",	"ε + σεν",	"ετω",	"σατω",	"εστιν",	"ην",	"εται",	"ε + ετο",	"σεται",	"ε + σατο",	"εσθω",	"σασθω",	"θησεται",	"ε + θη",	"θητω",	"dup + κεν",	"dup + ται",	"ε + dup + κει",	"ε + dup + το",	"ἔγνω",	"γνώτω"],
        ["1P",	"ομεν",	"ε + ομεν",	"σομεν",	"ε + σαμεν",	"------",	"------",	"εσμεν",	"ημεν",	"ομεθα",	"ε + ομεθα",	"σομεθα",	"ε + σαμεθα",	"------",	"------",	"θησομεθα",	"ε + θημεν",	"------",	"dup + καμεν",	"dup + μεθα",	"ε + dup + κειμεν",	"ε + dup + μεθα",	"ἔγνωμεν",	"------"],
        ["2P",	"ετε",	"ε + ετε",	"σετε",	"ε + σατε",	"ετε",	"σατε",	"εστε",	"ητε",	"εσθε",	"ε + εσθε",	"σεσθε",	"ε + σασθε",	"εσθε",	"σασθε",	"θησεσθε",	"ε + θητε",	"θητε",	"dup + κατε",	"dup + σθε",	"ε + dup + κειτε",	"ε + dup + σθε",	"ἔγνωσθε",	"γνώτε"],
        ["3P",	"ουσιν",	"ε + ον",	"σουσιν",	"ε + σαν",	"ετωσαν",	"σατωσαν",	"εισιν",	"ησαν",	"ονται",	"ε + οντο",	"σονται",	"ε + σαντο",	"εσθωσαν",	"σασθωσαν",	"θησονται",	"ε + θησαν",	"θητωσαν",	"dup + κασιν",	"dup + νται",	"ε + dup + κεισαν",	"ε + dup + ντο",	"ἔγνωσαν",	"γνώτωσαν"],
    ]
}



