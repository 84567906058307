import React, {useState} from "react";
import './renderg2me.css';
import useTheme from '@mui/material/styles/useTheme';
import Button from "@mui/material/Button";
import {hexToRgb} from "@mui/material";

function MarkupAnnoElement(props) {
    const handleClick = () => {
        if (props.clickCB !== undefined) {
            props.clickCB(props.content.index);
        }
    };

    let content = props.content;

    const theme = useTheme();

    const children = (content.children || []).map((item, index) => {
        return <MarkupAnnoElement key={index} clickCB={props.clickCB} content={item} selected={props.selected} selectedEnd={props.selectedEnd}/>
    });

    const getAnnotation = (classification, number) => {
        if(classification == 'none') return;
        let ans;
        switch (classification) {
            case 'noun_acc' : ans = 'A'; break;
            case 'noun_nom' : ans =  'N'; break;
            case 'noun_gen' : ans =  'G'; break;
            case 'noun_dat' : ans =  'D'; break;
            case 'verb_f'   : ans =  'VF'; break;
            case 'verb_nf'  : ans =  'VN'; break;
        }
        let num = number == 'plural' ? 'P' : 'S';
        if(classification[0] == 'n') ans += num;
        return ans;
    }

    let classification;
    let class1,class2,class3,class4 = '';
    let highlightColour = (theme.palette.mode == 'dark') ? 'blue' : 'lightgrey';
    let bgcolor = (props.selected == props.content.index || (props.selected < props.content.index) && props.content.index <= props.selectedEnd) ? highlightColour : (theme.palette.mode == 'dark') ? "rgba(0,0,0,0)" : theme.palette.primary.contrastText;
    let fgcolor = theme.palette.text.primary;


    let display;
    if(content.granularity == 'word') {
        classification = getAnnotation(content.role, content.number);
        if(classification && classification.length > 0) {
            if(content.role[0] == 'n') {
                class1 = "phrase NOUN";
                class2 = "phrase-pos NOUN font-large";
                if(content.number == 'plural') {
                    class1 += ' plural';
                }
                switch (content.role) {
                    case 'noun_acc' : class1 += ' acc'; class2 += ' acc'; break;
                    case 'noun_nom' : class1 += ' nom'; class2 += ' nom'; break;
                    case 'noun_gen' : class1 += ' gen'; class2 += ' gen'; break;
                    case 'noun_dat' : class1 +=  ' dat'; class2 += ' dat'; break;
                }
                class3 = "word w-NOUN";
                class4 = "word-pos NOUN";
            }
            else if(content.role[0] == 'v'){
                class1 = "phrase VERB";
                class2 = "phrase-pos VERB font-large";
                class3 = "word w-VERB";
                class4 = "word-pos VERB"
                if(content.role == 'verb_nf') {
                    class1 += ' nonfinite';
                }
            }

        }
        display = <>
            <span className={class1}　onClick={handleClick}>
            <ins className={class2}>{classification}</ins>
            <span className="word-container" style={{backgroundColor: bgcolor, color: fgcolor}}>
                <span className="word w-NOUN" style={{backgroundColor: bgcolor, color: fgcolor}}>
                    <ins className="word-pos NOUN">PRP</ins>
                    {content.text}
                    </span>
            </span>
        </span>
            {content.hasSpace ? <span className="word spc"> <ins className="word-pos"> </ins> </span> : null}
            </>
    } else if (content.granularity == 'group') {
        if(content.role == 'prepositional_phrase') {
            display = <><span style={{color: 'green'}}>(</span> <span>{children}</span> <span style={{color: 'green'}}>) </span></>;
        }
        else {
            display = <><span className={'clause phrase-container subordinate ADJ ref-phrase'} style={{
                paddingTop: "0.8em",
                paddingBottom: "1em", lineHeight: "4.8em"
            }}> <ins className="ref ADJ font-large">⇐</ins>
                {children}</span> {content.hasSpace ? <span className="word spc"> <ins className="word-pos"> </ins> </span> : null}</>;
        }
    }
        else if (content.granularity == 'syntax') {
            if (content.role == 'clause_break') {
                display = <span style={{color: 'green'}}>||<br /></span>
            }
        else if (content.role == 'verbal_phrase_break') {
                display = <span style={{color: 'green'}}>|<br /></span>
            }
    }




    return display;
}


export default MarkupAnnoElement;
