import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import React, {useState} from "react";
import Stack from "@mui/material/Stack";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import {GetParticiples, GetPrincipalParts, GetPrincipalPartsTest, GetVerbEndings, GetVerbs} from './words';
import {ConjugateVerb} from './VerbConjugation';
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import {InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

function getQuiz(type, direction) {
    let pp;
    if(type == 'participle') {
        pp = GetParticiples();
    }
    else if(type == 'parts') {
        pp = GetPrincipalParts();
    }
    else {
        pp = GetVerbEndings();
    }
    let header = pp[0];
    let quiz = [];
    let dict = {};
    let key, value;
    for (let i=1; i<pp.length; i++) {
        for (let j=1;j<header.length;j++) {
            if(direction == "recognize") {
                key = pp[i][j];
                value = pp[i][0] + ' ' + header[j];

            }
            else {
                key = pp[i][0] + ' ' + header[j];
                value = pp[i][j];
            }
            if(key == "------" || value == "------") {
                continue;
            }

            if(key in dict) {
                dict[key][0] += " / " + value;
            }
            else {
                dict[key] = [value, key];
            }
        }
    }

    for (key in dict) {
        quiz.push(dict[key]);
    }




    return quiz;
}

function VerbTraining(props) {
    const [verbs, setVerbs] = useState(GetVerbs());
    const [verbIndex, setVerbIndex] = useState(Math.floor(Math.random() * verbs.length));
    const [tense, setTense] = useState('Pre');
    const [voice, setVoice] = useState('Mid');
    const [person, setPerson] = useState(1);
    const [number, setNumber] = useState('S');
    const [showConjugation, setShowConjugation] = useState(false);
    const [showConjugatedVerb, setShowConjugatedVerb] = useState(true);
    const [princparts, setPrincParts] = useState(GetPrincipalParts());
    const [ppIndex, setPPIndex] = useState(Math.floor(Math.random() * princparts.length));
    //const [quizType, setQuizType] = useState('participle');
    const [quizType, setQuizType] = useState('endings');
    const [quizDirection, setQuizDirection] = useState('recognize');
    const [quiz, setQuiz] = useState(getQuiz(quizType, quizDirection));
    const [quizIndex, setQuizIndex] = useState(Math.floor(Math.random() * quiz.length));
    const [quizMissed, setQuizMissed] = useState([]);
    const [quizItemCount, setQuizItemCount] = useState(0);



    const NewVerb = () => {
        if (!showConjugation) {
            setShowConjugation(true);
            return;
        }
        setQuizItemCount(quizItemCount + 1);
        let tmpverbs = verbs;
        //let tenses = ['Pre', 'Imp', 'Fut', 'Aor'];
        let tenses = ['Per'];
        //let voices = ['Act', 'Mid', 'Pas'];
        let voices = ['Act', 'MP'];
        let persons = ['S', 'P'];
        tmpverbs.splice(verbIndex, 1);
        setVerbs(tmpverbs);
        if (verbs.length == 0) {
            setVerbs(GetVerbs());
        }
        let index = Math.floor(Math.random() * verbs.length);
        setVerbIndex(index);
        let tnum = Math.floor(Math.random() * tenses.length);
        setTense(tenses[tnum]);
        let vnum = Math.floor(Math.random() * voices.length);
        setVoice(voices[vnum]);
        let pnum = Math.floor(Math.random() * 3);
        setPerson(pnum + 1);
        let nnum = Math.floor(Math.random() * 2);
        setNumber(persons[nnum]);
        setShowConjugation(false);
        let tmpPP = princparts;
        tmpPP.splice(ppIndex, 1);
        setPrincParts(tmpPP);
        if (princparts.length == 0) {
            setPrincParts(GetPrincipalParts());
        }
        index = Math.floor(Math.random() * princparts.length);
        setPPIndex(index);
        let tmpQuiz = quiz;
        tmpQuiz.splice(quizIndex, 1);
        setQuiz(tmpQuiz);
        if (quiz.length == 0) {
            if (quizMissed.length > 0) {
                let newquiz = [...quizMissed];
                setQuiz(newquiz);
            }
            else {
                setQuiz(getQuiz(quizType, quizDirection));
            }
        }
        setQuizIndex(Math.floor(Math.random() * quiz.length))
    }

    const CorrectAnswer = () => {
        if(quizMissed.indexOf(quiz[quizIndex]) >= 0) {
            let missed = quizMissed;
            missed.splice(quizMissed.indexOf(quizIndex), 1);
            setQuizMissed(missed);
        }
        NewVerb();
    }

    const InCorrectAnswer = () => {
        if(quizMissed.indexOf(quiz[quizIndex]) < 0) {
            let missed = quizMissed;
            missed.unshift(quiz[quizIndex]);
            setQuizMissed(missed);
        }
        NewVerb();
    }

    const setQuizDirectionCommon = () => {
        setQuizMissed([]);
        setQuiz(getQuiz(quizType, quizDirection));
        setQuizItemCount(0);
        setQuizIndex(Math.floor(Math.random() * quiz.length));
        setShowConjugation(false);
    }

    const setType = (event) => {
        setQuizType(event.currentTarget.value);
        setQuizMissed([]);
        setQuiz(getQuiz(quizType, quizDirection));
        setQuizItemCount(0);
        setQuizIndex(Math.floor(Math.random() * quiz.length));
        setShowConjugation(false);
        //setQuizDirectionCommon();
    }

    const setDirection = (event) => {
        setQuizDirection(event.currentTarget.value);
        setQuizMissed([]);
        setQuiz(getQuiz(quizType, quizDirection));
        setQuizItemCount(0);
        setQuizIndex(Math.floor(Math.random() * quiz.length));
        setShowConjugation(false);
        //setQuizDirectionCommon();
    }

    const ShowAnswer = () => {
        setShowConjugation(true);
    }

    let verb = verbs[verbIndex];
    let princpart = princparts[ppIndex];

    //let conjugatedVerb = ConjugateVerb(verb, {tense: tense, person: person, number: number});

    let conjugatedVerb = ConjugateVerb(verb.normalize("NFD").replace(/\p{Diacritic}/gu, ""), {
        tense: tense, voice: voice, mood: 'Ind',
        person: person, number: number
    });

    let fulltenses = {Pre: 'Present', Imp: 'Imperfect', Fut: 'Future', Aor: 'Aorist', Per: 'Perfect'};
    let fullvoices = {Act: 'Active', Mid: 'Middle', Pas: 'Passive', MP: 'Middle/Passive'};
    let fullnumbers = {S: 'Singular', P: 'Plural'};

    let conjugation;
    if (showConjugation) {
        conjugation = <Item>
            <Grid container spacing={2} textAlign="left">
                <Grid item xs={12}>
                    {/*<Typography>Lexical root: {verb.normalize("NFD").replace(/\p{Diacritic}/gu, "")}</Typography>*/}
                    <Typography>Lexical root: {verb}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Tense: {fulltenses[tense]}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Voice: {fullvoices[voice]}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Person: {person}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Number: {fullnumbers[number]}</Typography>
                </Grid>
            </Grid>
        </Item>

    }

    let ppPres =
        <Grid item xs={2}>
            <Typography>{princpart[0]}</Typography>
        </Grid>

    let ppDisplay;
    if (showConjugation) {

    ppDisplay = princpart.slice(1).map((conj) => <Grid item xs={2}>
       <Typography>{conj}</Typography>
     </Grid>);
}

    let ppHeader =
        <Grid container spacing={2} textAlign="left">
            <Grid item xs={2}>
                <Typography>Pres</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography>Fut A/M</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography>Aor A/M</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography>Per A/M</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography>Per Pass</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography>Aor/Fut Pas</Typography>
            </Grid>
            {ppPres}
            {ppDisplay}
        </Grid>

    let conjugatedVerbDisplay;
    if(showConjugatedVerb) {
        conjugatedVerbDisplay =         <Item>
            <Typography> {conjugatedVerb} </Typography>
        </Item>
    }

    let stepLabel = (showConjugation) ? 'Next' : 'Answer';

    let quizSide1, quizSide2;
    let stateButton;
    console.log(quizIndex)
    console.log(quiz.length)

    quizSide1 = <Item>
        <Typography> {quiz[quizIndex][1]} </Typography>
    </Item>
    quizSide2 = <Item>
        <Typography> {"?"} </Typography>
    </Item>

    if(showConjugation) {
        quizSide2 = <Item>
            <Typography> {quiz[quizIndex][0]} </Typography>
        </Item>

        stateButton = <>
            <Grid item xs={4}>
                <Button onClick={CorrectAnswer}>Got it</Button>
            </Grid>
            <Grid item xs={4}>
                <Button onClick={InCorrectAnswer}>Missed</Button>
            </Grid>
        </>
    }
    else {
        stateButton = <>
            <Grid item xs={4}>
                <Button onClick={NewVerb}>{stepLabel}</Button>
            </Grid>
        <Grid item xs={4}>
        </Grid>
            </>
    }

    let hitrate = quizItemCount > 0 ? ((quizItemCount - quizMissed.length) / quizItemCount) * 100.0 : 0;
    let revisionHeader;
    if(quizMissed.length > 0) {
        revisionHeader = <Typography>You need to review these</Typography>
    }

    let quizTypes = [["Principal Parts", 'parts'], ["Participles", 'participle'], ["Verb Endings", 'endings']];
    let quizDirections = [["Recognize", 'recognize'], ["Generate", 'generate']]

    let typeSelector = <>
        <Grid item xs={6}>
            <RadioGroup
                row
                aria-label="quizType"
                value={quizType}
                onChange={setType}
                name="rgbQuiz"
            >
                {quizTypes.map((name, index) => <FormControlLabel key={index} value={name[1]} control={<Radio/>}
                                  label={name[0]}/>)}
            </RadioGroup>
        </Grid>
    <Grid item xs={6}>
        <RadioGroup
            row
            aria-label="quizDir"
            value={quizDirection}
            onChange={setDirection}
            name="rgbQuizDir"
        >
            {quizDirections.map((name, index) => <FormControlLabel key={index} value={name[1]} control={<Radio/>}
                                                              label={name[0]}/>)}
        </RadioGroup>
    </Grid>
        </>




    return <Stack spacing={1}>
        <Item>
            <Grid container spacing={2} textAlign="center">
                {typeSelector}
                {stateButton}

                <Grid item xs={4}>
                    <Typography> {parseInt(hitrate)}% Correct: {quiz.length} Remaining </Typography>
                </Grid>
            </Grid>
        </Item>
        {quizSide1}
        {quizSide2}
        <Grid item xs={12}>
            {revisionHeader}
            {quizMissed.map(question => <Typography color={"red"}>{question[1]}</Typography>)}
        </Grid>
        {/*ppHeader}
        {conjugatedVerbDisplay}
        {conjugation*/}
    </Stack>
}

export default VerbTraining;
