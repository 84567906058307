import Grid from "@mui/material/Grid";
import {Card, CardActionArea, CardContent, CardHeader} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

function UnitSelector(props) {
    const LessonSelect = (lesson) => {
        props.lessonCB(lesson)
    }

    const cardCB = (event) => {
        //console.log(event.target);
        //console.log(event.currentTarget.getAttribute('value'))
        LessonSelect(event.currentTarget.getAttribute('value'));
    }


    return (
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    alignItems="stretch"
                >
                    {props.lessons.map((elem, index) => (
                        <Grid item xs={12} sm={4} key={index}>
                            <Card
                                style={{ height: '100%' }}
                                value={elem.name}
                            >
                                <CardActionArea
                                    component="a"
                                    value={elem.name}
                                    onClick={cardCB}
                                >
                                    <CardHeader
                                        title={`Lesson ${elem.name}`}
                                    />
                                    <CardContent>
                                        <Typography variant="h5" gutterBottom>
                                            {elem.title}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>))}
                </Grid>);
}

export default UnitSelector;
