import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";


export default function RadioButtonsGroup(props) {
    const [valueNV, setValueNV] = React.useState(props.role);
    const [valueSyntax, setValueSyntax] = React.useState(props.syntax);
    const [pos, setPos] = React.useState(props.pos);

    const handleChangeNV = (event) => {
        setValueNV(event.target.value);
        if(props.changeNVCB != null) {
            props.changeNVCB(event.target.value);
        }
    };

    const handleChangeNumber = (event) => {
        if(props.changeNumberCB != null) {
            props.changeNumberCB(event.target.value);
        }
    }

    const handleChangeSyntax = (event) => {
        setValueSyntax(event.target.value);
        if(props.changeSyntaxCB != null) {
            props.changeSyntaxCB(event.target.value);
        }
    };

    const handlePos = (event) => {
        setPos(event.target.value);
    }


    let syntax;
    if (props.selectionType == 'phrase') {
        syntax =
        <>
            <Typography style={{ fontWeight: '600' }}>Syntax Types</Typography>
            <FormControl component="fieldset"
                         sx={{ flexDirection: { xs: "column", sm: "row" } }}>
            <RadioGroup
                row
                aria-label="Syntax"
                value={props.syntax}
                onChange={handleChangeSyntax}
                name="rgbSyn"
            >
                {/*<FormControlLabel value="CB" control={<Radio/>} label="Clause break"/>
                        <FormControlLabel value="VPB" control={<Radio/>} label="Verbal phrase break"/>*/}

                                <FormControlLabel value="prepositional_phrase" control={<Radio/>}
                                                  label="Prepositional phrase"/>

                {/*<FormControlLabel value="relative_clause" control={<Radio/>} label="Relative Clause"/>*/}

                        <FormControlLabel value="participle_phrase" control={<Radio/>}
                                          label="Participial phrase"/>
            </RadioGroup>
            </FormControl>
            </>
    }

    let person;

    if(props.selectionType == 'word')
    person = <>
        <Grid container spacing={0} alignItems="stretch">
            <Grid item xs={12}>
                <Typography><br /><br />Person</Typography>
            </Grid>
            <RadioGroup row aria-label="Person" >
                <Grid item xs={12}>
                    <FormControlLabel value="first" control={<Radio/>} label="First"/>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel value="second" control={<Radio/>} label="Second"/>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel value="third" control={<Radio/>} label="Third"/>
                </Grid>
            </RadioGroup>
        </Grid>
        </>

    let number;

    let groupOption, wordOption;

    if(props.selectionType == 'word') {
        wordOption = <>
            <FormControlLabel value="noun" control={<Radio/>} label="Noun"/>
            <FormControlLabel value="verb" control={<Radio/>} label="Verb"/>
        </>
    } else {
        groupOption = <FormControlLabel value="group" control={<Radio/>} label="Syntax"/>
    }

    let posChooser =
        <FormControl component="fieldset"
                     sx={{flexDirection: {xs: "column", sm: "row"}}}>
            <RadioGroup row aria-label="pos" value={pos} onChange={handlePos}>
                {wordOption}
            </RadioGroup>
        </FormControl>


    let noun;
    if(pos == 'noun' && props.selectionType == 'word') {
        noun = <>
            <Typography style={{ fontWeight: '600' }}>Case</Typography>
            <FormControl component="fieldset"
                         sx={{ flexDirection: { xs: "column", sm: "row" } }}>
                <RadioGroup
                    row
                    aria-label="Nouns"
                    value={props.role}
                    onChange={handleChangeNV}
                    name="rbgN"
                >

                    <FormControlLabel value="noun_nom" control={<Radio/>} label="Nominative"/>
                    <FormControlLabel value="noun_gen" control={<Radio/>} label="Genitive"/>
                    <FormControlLabel value="noun_dat" control={<Radio/>} label="Dative"/>
                    <FormControlLabel value="noun_acc" control={<Radio/>} label="Accusative"/>
                </RadioGroup>
            </FormControl>
        </>

        if(props.selectionType != 'group')
        number =
            <>
                <Grid item xs={6} sm={6}>
                    <Grid container spacing={0} alignItems="stretch">
                        <Grid item xs={12}>
                            <Typography style={{ fontWeight: '600' }}>Number</Typography>
                        </Grid>
                        <FormControl component="fieldset"
                                     sx={{ flexDirection: { xs: "column", sm: "row" } }}>
                        <RadioGroup row aria-label="Number" value={props.number} onChange={handleChangeNumber}>
                                <FormControlLabel value="singular" control={<Radio/>} label="Singular"/>
                                <FormControlLabel value="plural" control={<Radio/>} label="Plural"/>
                        </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </>
    }

    let verb;
    if(pos == 'verb') {
        verb = <>
            <FormControl component="fieldset">
                <RadioGroup
                    row
                    aria-label="Verbs"
                    value={props.role}
                    onChange={handleChangeNV}
                    name="rbgV"
                >
                    <FormControlLabel value="verb_f" control={<Radio/>} label="Finite"/>
                    <FormControlLabel value="verb_nf" control={<Radio/>} label="Non-finite"/>
                </RadioGroup>
            </FormControl>
        </>
    }



    let dis =<>
        <Grid container spacing={0} alignItems="stretch">
            <Grid item xs={12}>{posChooser}</Grid>
            <Grid item xs={12}>
                {noun}
                {verb}
                {number}
                {syntax}
            </Grid>
        </Grid>
    </>



    return (
        dis
    );
}


