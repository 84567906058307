function ApplyContractewRules(stem, ending) {
    // check diphthongs
    if(ending.length > 1) {
        if(ending[1] == 'ι' || ending[1] == 'u'){
            if(ending[0] == 'α' || ending[0] == 'ε' || ending[0] == 'η' || ending[0] == 'ο') {
                return stem.substring(0, stem.length-1) + ending;
            }
        }
        if(ending[0] == 'ω' || ending[0] == 'η') {
            return stem.substring(0, stem.length-1) + ending;
        }
    }
    if(ending[0] == 'ο') {
        return stem.substring(0, stem.length-1)  + 'ου' + ending.substring(1, ending.length)
    }
    if(ending[0] == 'ε') {
        return stem.substring(0, stem.length-1)  + 'ει' + ending.substring(1, ending.length)
    }
    return stem + ending;
}

export function AdjustStem(stem, tense, voice) {
    // Deal with Perfect
    if(tense == 'Per') {
        let stemEnd = stem.charAt(stem.length-1);
        if(stemEnd == 'ε') {
            stem = stem.substring(0, stem.length - 1) + 'η';
        }
        return stem[0] + 'ε' + stem;
    }


    // adjust for εω verbs in Fut and Aor
    let passiveBit;
    if(voice == 'Pas') {
        if(tense == 'Fut') {
            passiveBit = 'θησ';
        }
        if(tense == 'Aor') {
            passiveBit = 'θη';
        }
    }


    if((tense == 'Fut' || tense == 'Aor')){
        let stemEnd = stem.charAt(stem.length-1);
        if(stemEnd == 'ε') {
            if(voice == 'Pas') {
                stem = stem.substring(0, stem.length - 1) + 'η' + passiveBit;
            } else {
                stem = stem.substring(0, stem.length - 1) + 'ησ';
            }
            return stem;
        }
        if(stemEnd == 'θ' || stemEnd == 'δ' || stemEnd == 'τ' || stemEnd == 'ζ') {
            if(voice == "Pas") {
                stem = stem.substring(0,stem.length-1) + passiveBit;
            }
            else {
                stem = stem.substring(0, stem.length - 1) + 'σ';
            }
            return stem;
        }
        if(stemEnd == 'κ' || stemEnd == 'γ' || stemEnd == 'χ') {
            if(voice == "Pas") {
                stem = stem.substring(0,stem.length-1) + 'χ' + passiveBit;
            }
            else {
                stem = stem.substring(0,stem.length-1) + 'ξ';
            }
            return stem;
        }
        if(stemEnd == 'π' || stemEnd == 'β' || stemEnd == 'φ') {
            if(voice == "Pas") {
                stem = stem.substring(0,stem.length-1) + 'φ' + passiveBit;
            }
            else {
                stem = stem.substring(0,stem.length-1) + 'ψ';
            }
            return stem;
        }
        if(voice == "Pas") {
            stem = stem + passiveBit;
        }
        else {
            stem = stem + 'σ';
        }
        return stem;
    }
    return stem;
}

function ApplyRules(stem, ending) {
    if(stem.charAt(stem.length-1) == 'ε') {
        return ApplyContractewRules(stem, ending);
    }

    return stem + ending;
}

export function AddEnding(verb, tense, voice, mood, person, number) {
    let key = tense + "_" + voice;
    if(tense == 'Fut') {
        if(voice == "Act") {
            key = 'Pre_Act';
        }
        if(voice == "Mid" || voice == "Pas") {
            key = 'Pre_MP';
        }
    }


    let prevEndChar = verb.charAt(verb.length - 2);
    let ew = prevEndChar == 'ε' ? true: false;
    if(tense == 'Imp' && ew && voice == "Act") {
        key = 'Impew_' + voice;
    }
    if((voice == "Mid" || voice == "Pas") && (tense == "Pre" || tense == "Imp")) {
        key = tense + "_MP";
    }

    let endings = {
        Pre_Act: ['ω', 'εις', 'ει', 'ομεν', 'ετε', 'ουσιν'],
        Imp_Act: ['ον', 'ες', 'εν', 'ομεν', 'ετε', 'ον'],
        Impew_Act: ['oν', 'ες', 'ε', 'ομεν', 'ετε', 'ον'],
        Aor_Act: ['α', 'ας', 'εν', 'αμεν', 'ατε', 'αν'],
        Pre_MP: ['ομαι', 'η', 'εται', 'ομεθα', 'εσθε', 'ονται'],
        Imp_MP: ['ομην', 'ου', 'ετο', 'ομεθα', 'εσθε', 'οντο'],
        Aor_Mid: ['αμην', 'ω', 'ατο', 'αμεθα', 'ασθε', 'αντο'],
        Aor_Pas: ['ν', 'ς', '', 'μεν', 'τε', 'σαν'],
        Per_Act: ['κα', 'κας', 'κεν', 'καμεν', 'κατε', 'κασιν'],
        Per_MP: ['μαι', 'σαι', 'ται', 'μεθα', 'σθε', 'νται']
    };

    let index = (number == 'S') ? person - 1: person -1 + 3;
    if(index < 0 || index > 5 || !(key in endings)) {
        console.log('Problem with ' + verb + ' ' + index + ' ' + key)
        return '';
    }
    let ending = endings[key][index];

    let stem = AdjustStem(verb.substring(0, verb.length-1), tense, voice);
    let combo = ApplyRules(stem, ending);
    return combo;
}

function SpecialAugument(verb) {
    return '';
}

export function AddAugment(verb) {
    let special = SpecialAugument(verb);
    if(special.length > 0) {
        return special;
    }
    // long vowels and long 'i' sounding vowels reject augment
    //console.log('Coming in with ' + verb);
    if(verb.length > 0 && (verb[0] == 'η' || verb[0] == 'ω' || verb[0] == 'ι' || verb[0] == 'υ') ) {
        //console.log('Leaving with nothing');
        return verb;
    }
    //lengthening
    if(verb.length > 1 && (verb[1] != 'ι' && verb[1] != 'υ')) {
        if((verb[0] == 'α' || verb[0] == 'ε')) {
            //console.log('Leaving with n');
            return 'η' + verb.substring(1, verb.length);
        }
        if(verb[0] == 'ο') {
            //console.log('Leaving with w');
            return 'ω' + verb.substring(1, verb.length);
        }
    }
    // diphthongs with iota lengthen and iota goes to subscript
    if(verb.length > 1 && verb[1] == 'ι'){
        if(verb[0] == 'α' || verb[0] == 'ε') {
            //console.log('Leaving with ni');
            return 'ῃ' + verb.substring(2, verb.length);
        }
        if(verb[0] == 'o') {
            //console.log('Leaving with wi');
            return 'ῳ' + verb.substring(2, verb.length);
        }
    }
    if(verb.length > 1 && verb[1] == 'υ') {
        if(verb[0] == 'α' || verb[0] == 'ε') {
            //console.log('Leaving with n diph');
            return 'ηυ' + verb.substring(2, verb.length);
        }
    }

    //console.log('Leaving with e');
    return 'ε' + verb;
}

export function AddBeginning(verb, tense, mood) {
    if(mood == 'Imp') {
        return verb;
    }
    //todo compound verbs
    if(tense == 'Imp' || tense == 'Aor') {
        return AddAugment(verb);
    }
    return verb;
}

export function ConjugateVerb(lexRoot, conjugation) {
    let newBeginning = AddBeginning(lexRoot, conjugation.tense, conjugation.mood);
    return AddEnding(newBeginning, conjugation.tense, conjugation.voice, conjugation.mood, conjugation.person, conjugation.number);
}
