import React, {useState} from 'react'
import MarkupAnnoElement from './MarkupAnnoElement';
import MarkupClassification from "./MarkupClassification";
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import {ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import ShortTextIcon from '@mui/icons-material/ShortText';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import Rating from '@mui/material/Rating';

import './renderg2me.css';
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import useTheme from '@mui/material/styles/useTheme';


import word from "text-annotation-graphs/src/js/components/word";




const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.primary,
}));

// Use hierarchical structure of passage for rendering to create hierarchy
// of spans with associated markup


function MarkupAnno(props) {
    const [selected, setSelected] = useState(-1);
    const [selectionType, setSelectionType] = useState('word');
    const [selectedEnd, setSelectedEnd] = useState(-1);
    const [showAnswer, setShowAnswer] = useState(false);

    const textClickCBOrig = (item) => {
        if (item != selected) {
            setSelected(item);
        } else {
            setSelected(-1);
        }
    }

    const textClickCB = (item) => {
        if (selectionType == 'word') {
            if (item != selected) {
                setSelected(item);
            } else {
                setSelected(-1);
            }
            return;
        } else if (selectionType == 'phrase') {
            if (selected == -1) {
                setSelected(item);
                return;
            }
            if (selectedEnd == -1 && item == selected) {
                return;
            }
            if (item > selected) {
                setSelectedEnd(item);
            } else if (item < selected) {
                if(selectedEnd == -1) {
                    setSelectedEnd(selected);
                }
                setSelected(item)
            }
        }
    }


    const roleAssignCB = (role) => {
        if (selected >= 0 && selected < props.passage.markupAttempt.words.length) {
            let word = props.passage.markupAttempt.words[selected];
            props.updateWordCB(selected, role, word.number);
        }
    }

    const numberAssignCB = (number) => {
        if (selected >= 0 && selected < props.passage.markupAttempt.words.length) {
            let word = props.passage.markupAttempt.words[selected];
            props.updateWordCB(selected, word.role, number);
        }
    }


    const syntaxAssignCB = (syntax) => {
        let enhancedSyntax = {start: selected, end: selectedEnd >= 0 ? selectedEnd : selected, role: syntax};
        setSelected(-1);
        setSelectedEnd(-1);
        props.updateSyntaxCB(enhancedSyntax);
    }

    const previousPassage = () => {
        setSelected(-1);
        setShowAnswer(false);
        props.changePassage(-1);
    }

    const nextPassage = () => {
        setSelected(-1);
        setShowAnswer(false);
        props.changePassage(1);
    }

    const isEqualsJson = (obj1, obj2) => {
        let keys1 = Object.keys(obj1);
        let keys2 = Object.keys(obj2);

        //return true when the two json has same length and all the properties has same value key by key
        return keys1.length === keys2.length && Object.keys(obj1).every(key => obj1[key] == obj2[key]);
    }

    let preps = ["ἐν","ἐν", "πρὸς", "ἐκ", "μετὰ", "εἰς", "παρὰ", "ἐξ", "ἄχρι", "ἐπὶ","διὰ", "μετ’", "κατὰ", "περὶ", "ἀπὸ", "ἀπʼ", "ἀπ’", "πρὸ","ὀπίσω", "οὐ", "οὐκ", "οὐχ"];

    const assess = () => {
        if (props.passage.markupAnswer.length == 0 || props.passage.markupAnswer.words.length == 0) {
            return 0;
        }
        let match = 0;
        let nocounts = 0;
        for (let i = 0; i < props.passage.markupAttempt.words.length; i++) {
            if(preps.includes(props.passage.markupAttempt.words[i].text) ||
                props.passage.markupAnswer.words[i].role == 'none'){
                nocounts++;
                continue;
            }
            if (props.passage.markupAttempt.words[i].role == props.passage.markupAnswer.words[i].role) {
                if (props.passage.markupAttempt.words[i].role.charAt(0) == 'v' ||
                    props.passage.markupAttempt.words[i].number == props.passage.markupAnswer.words[i].number
                )
                    match++;
            }
        }
        return (5.0 * match / (props.passage.markupAttempt.words.length - nocounts));
    }


    const getContent = (contents, index) => {
        if (contents.granularity === 'word') {
            //console.log(contents);
            return <MarkupAnnoElement key={index} id={index} text={contents.text} role={contents.role}
                                      clickCB={textClickCB}/>
        } else if (contents.granularity === 'group') {
            console.log(contents.children);
            let innerComponents = contents.children.map((item, index2) => {
                getContent(item, index2);
            });
            console.log(innerComponents);
            return <MarkupAnnoElement key={index} id={index} text={contents.text} role={contents.role}
                                      clickCB={textClickCB}>{innerComponents}</MarkupAnnoElement>
        }
    }

    const getLevel = (groupNum, groups) => {
        if(groups[groupNum].parent == -1) {
            return 1;
        }
        return 1 + getLevel(groups[groupNum].parent, groups);
    }

    const markupToAnnotation = (markup) => {
        let syntax, syntax2, anno, wordNum;
        let wordgroups = markup.words.map((item, index) => {
           return {type: 'word', start: index, parent: -1};
        });
        let groupgroups = markup.syntax.map((item, index) => {
            return {type: 'group', start: item.groupStart, parent: -1, groupNum: index, children: []};
        });

        for (let s=0;s<markup.syntax.length;s++) {
            syntax = markup.syntax[s];
            for(let index=syntax.groupStart; index <= syntax.groupEnd; index++) {
                wordgroups[index].parent = s;
            }
            for(let s2=s+1;s2<markup.syntax.length;s2++) {
                syntax2 = markup.syntax[s2];
                if(syntax2.groupStart>=syntax.groupStart && syntax2.groupStart < syntax.groupEnd) {
                    groupgroups[s2].parent = s;
                }
            }
        }

        for (let s=0;s<markup.syntax.length;s++) {
            syntax = markup.syntax[s];
            for(let index=syntax.groupStart; index <= syntax.groupEnd; index++) {
                if (wordgroups[index].parent == s) {
                    groupgroups[s].children.push({type: 'word', start: index});
                }
            }
            for(let s2=s+1;s2<markup.syntax.length;s2++) {
                syntax2 = markup.syntax[s2];
                if(syntax2.groupStart>=syntax.groupStart && syntax2.groupStart < syntax.groupEnd) {
                    if(groupgroups[s2].parent == s) {
                        groupgroups[s].children.push(groupgroups[s2]);
                    }
                }
            }
        }

        for (let s=0;s<markup.syntax.length;s++) {
            groupgroups[s].level = getLevel(s, groupgroups);
            groupgroups[s].children.sort((a, b) => {
                return a.start - b.start;
            });
        }

        let levelSortedGroups = [...groupgroups];

        levelSortedGroups.sort((a,b) => {
            return b.level - a.level;
        });
        //console.log(levelSortedGroups)

        let RootNodes = [];

        for(const group of levelSortedGroups) {
            syntax = markup.syntax[group.groupNum];
            anno = {
                granularity: syntax.granularity,
                index: 0,
                role: syntax.role,
                text: "",
                hasSpace: true,
                children: []
            }
            for(const child of group.children) {
                if(child.type == 'word') {
                    wordNum = child.start;
                    anno.children.push({
                        granularity: 'word',
                        index: wordNum,
                        role: markup.words[wordNum].role,
                        number: markup.words[wordNum].number,
                        text: markup.words[wordNum].text,
                        hasSpace: true,
                        children: []
                    });
                }
                else if(child.type == 'group') {
                    let childGroup = groupgroups[child.groupNum];
                    anno.children.push(childGroup.anno);
                }
            }

            anno.children[anno.children.length-1].hasSpace = false;

            group.anno = anno;
            if(group.parent == -1) {
                RootNodes.push(group);
            }
        }

        for(let wordNum=0;wordNum<markup.words.length;wordNum++) {
            if(wordgroups[wordNum].parent == -1) {
                anno = {
                    granularity: 'word',
                    index: wordNum,
                    role: markup.words[wordNum].role,
                    number: markup.words[wordNum].number,
                    text: markup.words[wordNum].text,
                    hasSpace: true,
                    children: []
                };
                wordgroups[wordNum].anno = anno;
                RootNodes.push(wordgroups[wordNum]);
            }
        }

        RootNodes.sort((a, b) => {
            return a.start - b.start;
        });

        let annotation = RootNodes.map((item) => {
            return item.anno;
        });

        return annotation;


    }


    const markupToAnnotation_old = (markup) => {
        return markupToAnnotation2(markup);
        let annotation = [];
        let wordCount = 0;
        let syntaxCount = 0;
        let wordHasSpace;
        for (const elem of markup.syntax) {
            if (elem.groupStart > wordCount) {
                for (let wordNum = wordCount; wordNum < elem.groupStart; wordNum++) {
                    annotation.push({
                        granularity: 'word',
                        index: wordNum,
                        role: markup.words[wordNum].role,
                        number: markup.words[wordNum].number,
                        text: markup.words[wordNum].text,
                        hasSpace: true,
                        children: []
                    });
                }
                wordCount = elem.groupStart;
            }
            if (elem.groupStart == wordCount) {
                let children = [];
                for (let wordNum = elem.groupStart; wordNum <= elem.groupEnd; wordNum++) {
                    //if(wordNum == elem.groupEnd && elem.role == 'prepositional_phrase') {
                    if(wordNum == elem.groupEnd) {
                        wordHasSpace = false;
                    }
                    else {
                        wordHasSpace = true;
                    }
                    children.push({
                        granularity: 'word',
                        index: wordNum,
                        role: markup.words[wordNum].role,
                        number: markup.words[wordNum].number,
                        text: markup.words[wordNum].text,
                        hasSpace: wordHasSpace,
                        children: []
                    });
                }
                annotation.push({
                    granularity: elem.granularity,
                    index: syntaxCount,
                    role: elem.role,
                    text: "",
                    children: children
                })
                wordCount = elem.groupEnd + 1;
                syntaxCount++;
            }
        }
        if (wordCount < markup.words.length) {
            for (let wordNum = wordCount; wordNum < markup.words.length; wordNum++) {
                annotation.push({
                    granularity: 'word',
                    index: wordNum,
                    role: markup.words[wordNum].role,
                    number: markup.words[wordNum].number,
                    text: markup.words[wordNum].text,
                    hasSpace: true,
                    children: []
                });
            }
        }
        return annotation;
    }


    //console.log(props.passage)
    let annotation = props.passage.markupAttempt.words.length > 0 ?
        markupToAnnotation(props.passage.markupAttempt) :
        markupToAnnotation({
            words: props.passage.text.split(" ").map((item, index) => {
                return {granularity: 'word', role: 'none', number: 'singular', person: 'none', text: item, children: []}
            }),
            syntax: []
        });

    let answerAnnotation = props.passage.markupAnswer.words.length > 0 ? markupToAnnotation(props.passage.markupAnswer) : [];


    let renderList = annotation.map((item, index) => {
        return <MarkupAnnoElement key={index} clickCB={textClickCB} content={item} selected={selected} selectedEnd={selectedEnd}/>
    });

    let answerRenderList = answerAnnotation.map((item, index) => {
        return <MarkupAnnoElement key={index} content={item}/>
    });

    const backForward =
        <Grid container spacing={0}>
            <Grid item xs={6}>
                <Tooltip title={'Previous'}>
                    <IconButton onClick={previousPassage}><ArrowBackIosIcon/></IconButton>
                </Tooltip>
            </Grid>
            <Grid item xs={6}>
                <Tooltip title={'Next'}>
                    <IconButton onClick={nextPassage}><ArrowForwardIosIcon/></IconButton>
                </Tooltip>
            </Grid>
        </Grid>


    function clearAllFormatting() {
        if (confirm("Delete all markup?")) {
            props.deleteMarkUpCB();
        }
    }

    const framework = (renderList) => {
        return <div className="g2me-result">
                    <span className="sentence odd"><span className="clause phrase-container"
                                                         style={{lineHeight: '3em'}}><span
                        className="clause phrase-container"><span
                        className="clause phrase-container right-padding" style={{lineHeight: '3em'}}>
                        {renderList}
                    </span></span></span></span>
        </div>
    }

    let answer;
    let showAnswerIcon;
    let showAnswerTitle;
    if (showAnswer && answerRenderList.length > 0) {
        answer = <Item>{framework(answerRenderList)}</Item>;
        showAnswerIcon = <VisibilityIcon/>
        showAnswerTitle = "Hide Answer";
    } else {
        answer = undefined;
        showAnswerIcon = <VisibilityOffIcon/>
        showAnswerTitle = answerRenderList.length > 0 ? "Show answer" : "No answer available";
    }

    const switchShowAnswer = () => {
        if (answerAnnotation.length < 0) return;
        setShowAnswer(!showAnswer);
    }

    let pos;
    /* both noun and none will let noun markup appear in classifier*/
    if (selected >= 0 && selected < props.passage.markupAttempt.words.length) {
        if (props.passage.markupAttempt.words[selected].role.charAt(0) == 'n') {
            pos = 'noun';
        } else if (props.passage.markupAttempt.words[selected].role.charAt(0) == 'v') {
            pos = 'verb';
        }
    }

    let remainingPassage = props.passage.markupAttempt.words.slice(selected+1);


    const handleSelectionType =  (event, chosenSelectionType) => {
        setSelectionType(chosenSelectionType);
        setSelected(-1);
        setSelectedEnd(-1);
    }

    return (
                <Stack spacing={1}>
                    <Item>
                        <Grid container spacing={0} textAlign="center">
                            <Grid item xs={4} sm={3}>
                        {backForward}
                            </Grid>

                            <Grid item xs={4} sm={3}>
                                <ToggleButtonGroup
                                    value={selectionType}
                                    exclusive
                                    onChange={handleSelectionType}
                                    aria-label="text selection"
                                >
                                    <Tooltip title={"Single word select"}>
                                    <ToggleButton value="word" aria-label="left aligned">
                                        <ShortTextIcon />
                                    </ToggleButton>
                                    </Tooltip>
                                    <Tooltip title={"Multiple word select"}>
                                    <ToggleButton value="phrase" aria-label="centered">
                                        <TextSnippetIcon />
                                    </ToggleButton>
                                    </Tooltip>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <Tooltip title={showAnswerTitle}>
                                    <IconButton onClick={switchShowAnswer}> {showAnswerIcon} </IconButton>
                                </Tooltip>
                                <Tooltip title={"Delete all markup"}>
                                    <IconButton onClick={clearAllFormatting}> <RemoveCircleIcon  /> </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <IconButton>
                                    <Rating name="half-rating" readOnly value={assess()} precision={0.5}  />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Item>
                    <Item>
                        {framework(renderList)}
                    </Item>
                        {answer}
                        {(selected >=0) && <Item><MarkupClassification selectionType={selectionType} role={props.passage.markupAttempt.words[selected].role} pos={pos} number={props.passage.markupAttempt.words[selected].number} changeNumberCB={numberAssignCB}
                                                                       changeNVCB={roleAssignCB}  changeSyntaxCB={syntaxAssignCB} remainingWords={remainingPassage}/></Item>}
                </Stack>
    );
}

export default MarkupAnno;
