import React, {useState} from 'react'
import MarkupElement from './MarkupElement2';
import MarkupClassification from "./MarkupClassification";
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import {RadioGroup} from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));



function Markup(props) {
    let words = props.passage.text.split(" ");
    let componentList = words.map((item, index) =>{
        return {role: 'nothing', syntax: 'none', text: item}
    });

    const [selectedItem, setSelectedItem] = useState(-1);
    const [markup, setMarkup] = useState(componentList);

    const markupFromSyntax = (syntax) => {
        switch (syntax) {
            case "Clause break" :
                return "||"
        }
        return "";
    }

    const textClickCB = (item) => {
        console.log('clicked ' + item + ' sel item ' + selectedItem + ' role ' + markup[item].role + ' syntax ' + markup[item].syntax);
        if(item != selectedItem) {
            setSelectedItem(item);
        }
        else {
            setSelectedItem(-1);
        }
    }

    const roleAssignCB = (role) => {
        console.log(role);
        if (selectedItem >= 0 && selectedItem < wgList.length) {
            let tmpMarkup = [...markup];
            let element = tmpMarkup[selectedItem];
            element.role = role;
            tmpMarkup[selectedItem] = element;
            setMarkup(tmpMarkup);
        }
    }

    const syntaxAssignCB = (syntax) => {
        console.log(syntax);
        if (selectedItem < 0 || selectedItem >= wgList.length) {
            return;
        }
        let tmpMarkup = [...markup];
        let element = tmpMarkup[selectedItem];
        element.syntax = syntax;
        tmpMarkup[selectedItem] = element;
        setMarkup(tmpMarkup);

    }


    let wgList = markup.map((item, index) =>{
        return <MarkupElement wordGroup={item} id={index} key={index} selectCB={textClickCB} selected={selectedItem == index}/>
    })


    return (
                <Stack spacing={2}>
                    <Item>
                        {wgList}
                    </Item>
                    <Item>
                        {(selectedItem >= 0) && <MarkupClassification role={markup[selectedItem].role}
                             changeNVCB={roleAssignCB} syntax={markup[selectedItem].syntax} changeSyntaxCB={syntaxAssignCB}/>}
                    </Item>
                </Stack>

    );
}

export default Markup;
